<div class="row" *ngIf="add">
    <div class=" col-md-12 modal-header pb-0 text-end">
        <button class="button-close" (click)="closeDialog()">
            <img src="assets/images/arrow_close.svg">
        </button>
    </div>
</div>
<div class="main">
    <div *ngIf="!myProfile" class="d-flex mb-3 back align-items-center">
        <img class="cursor gb" src="assets/images/arrow-circle-left.svg">
        <h3 class="ms-2 mb-0 cursor">Back</h3>
    </div>
    <div Class="row m-0 ">
        <div class="hideonmodal col-lg-2 p-0 profile mt-0 position-relative" *ngIf="update">
            <app-profile-front-page></app-profile-front-page>
        </div>
        <div class="bg-white py-3 banner " [ngClass]="update ? 'col-md-10' : 'col-md-12'">
            <div class="row headerbox">
                <div class="col-md-12 justify-content-center d-flex align-items-end" *ngIf="add">
                    <!-- {{add | json}} -->
                    <img class="img1" src="assets/images/new_img/raftar-logo.svg" alt="">
                    <h3 class="head3 mb-4">Post Your Enquiry </h3>

                </div>
                <div class="col-md-12" *ngIf="update">
                    <h3 class="head3 mb-4">Enquiries</h3>
                </div>
                <!-- <div class="col-md-4 text-end">
                </div> -->
            </div>
            <div class="p-0 mainnn">
                <div class="row m-0 d-flex  justify-content-center bg-color">
                    <div class="col-lg-12 reset ">
                        <form [formGroup]="loginForm" (submit)="onSubmit(loginForm.value)">
                            <div class="phonedetail d-grid ">
                                <div class="customwidth position-relative mt-2">
                                    <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                        placeholder="Full Name" formControlName="full_name">
                                    <!-- <div *ngIf="loginForm.get('full_name')?.invalid && (loginForm.get('full_name')?.touched || loginForm.get('full_name')?.dirty)"
                                        class="error-message">
                                        Full Name is required.
                                    </div> -->
                                </div>

                                <div class="customwidth position-relative mt-2">
                                    <input type="email" class="form-control emaildata ps-3" maxlength="50"
                                        (keydown)="validators.avoidSpace($event)" placeholder="Email"
                                        formControlName="email">
                                    <!-- <div *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)"
                                        class="error-message">
                                        <div *ngIf="loginForm.get('email')?.errors?.required">Email is required.</div>
                                        <div *ngIf="loginForm.get('email')?.errors?.pattern">Invalid email format.</div>
                                    </div> -->
                                </div>
                                <div class="customwidth position-relative  mt-2">
                                    <p class="position-absolute c_input border-end pe-2 me-2">+91 </p>
                                    <input type="text" class="form-control countrycode" formControlName="phone_no"
                                        maxlength="10" placeholder="Enter Phone Number" appDigitOnly>
                                </div>
                                <div class="customwidth position-relative mt-2">
                                    <mat-form-field appearance="fill" class="mat-select-display">
                                        <mat-select placeholder="Select Offerings" #select2
                                            (openedChange)="onSelectOpenClose($event, 'category')"
                                            formControlName="industry_id"
                                            (selectionChange)="onCategoryChange($event.value)">
                                            <!-- {{industry | json}} -->
                                            <mat-option *ngFor="let category1 of category" [value]="category1.id">
                                                {{category1.industry_name}}
                                            </mat-option>
                                            <mat-option *ngIf="category?.length == 0" disabled>No items
                                                available</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="customwidth position-relative mt-2">
                                    <mat-form-field appearance="fill" class="mat-select-display">
                                        <mat-select placeholder="Select Industry" #select1
                                            (openedChange)="onSelectOpenClose($event, 'industry')"
                                            formControlName="category_id"
                                            (selectionChange)="onIndustryChange($event.value)">
                                            <mat-option *ngFor="let industry1 of industry" [value]="industry1.id">
                                                {{industry1.sub_category_name}}
                                            </mat-option>
                                            <mat-option *ngIf="industry?.length == 0" disabled>No items
                                                available</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="!machine">
                                    <div class="customwidth position-relative mt-2">
                                        <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Company Name" formControlName="company_name">
                                    </div>
                                </div>
                                <div class="locateyourdata mt-2 align-items-center">
                                    <input type="text" appAlphaNumOnly maxlength="30"
                                        class="form-control emaildata ps-3" formControlName="location"
                                        placeholder="Location">
                                    <img src="assets/images/location_2.svg" class="calendar" alt="location">
                                </div>
                                <div *ngIf="!isPropertyIndustrySelected && !machine">
                                    <div class="customwidth position-relative mt-2">
                                        <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Service Name" formControlName="service_name">
                                    </div>
                                    <div class="customwidth position-relative mt-2">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                                    placeholder="Timings (from)" formControlName="timing_from">
                                            </div>
                                            <div class="col-md-6">
                                                <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                                    placeholder="Timings (to)" formControlName="timing_to">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- {{isPropertyIndustrySelected | json}} -->
                                <div *ngIf="!isPropertyIndustrySelected && machine">
                                    <div class="customwidth position-relative mt-2">
                                        <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Product Name" formControlName="product_name">
                                        <!-- <div *ngIf="loginForm.get('product_name')?.invalid && (loginForm.get('product_name')?.touched || loginForm.get('product_name')?.dirty)"
                                            class="error-message">
                                            Product Name is required.
                                        </div> -->
                                    </div>
                                    <div class="customwidth position-relative mt-2">
                                        <input type="number" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Quantity" formControlName="quantity">
                                    </div>
                                    <div class="customwidth position-relative mt-2">
                                        <mat-form-field appearance="fill" class="mat-select-display">
                                            <mat-select placeholder="Select Brand" #select2
                                                (openedChange)="onSelectOpenClose($event, 'brand')"
                                                formControlName="brand_id"
                                                (selectionChange)="onIndustryChange($event.value)">
                                                <mat-option *ngFor="let industry1 of brands" [value]="industry1.id">
                                                    {{industry1.brand_name}}
                                                </mat-option>
                                                <mat-option *ngIf="industry?.length == 0" disabled>No items
                                                    available</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <!-- <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Brand" formControlName="brand_id"> -->
                                    </div>
                                </div>
                                <div *ngIf="isPropertyIndustrySelected">
                                    <div class="customwidth position-relative mt-2">
                                        <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Required Area (sqft)" formControlName="req_area">
                                    </div>
                                </div>
                                <div class="customwidth position-relative mt-2">
                                    <div class="row mt-2">
                                        <div class="col-md-10">
                                            <p><span class="loan ms-3">Required Loan</span></p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="toggle-switch">
                                                <input type="checkbox" id="toggle"
                                                    [checked]="loginForm.get('loan_required')?.value === true "
                                                    (change)="onToggleChange($event, 'loan_required')" />
                                                <label for="toggle" class="slider"></label>
                                            </div>
                                            <!-- <app-toggle-switch [(isToggled)]="loginForm.get('loan_required').value"
                                            formControlName="loan_required">
                                        </app-toggle-switch> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="customwidth position-relative mt-2">
                                    <textarea type="text" class="form-control emaildata ps-3" maxlength="1500"
                                        placeholder="Brief Description (max 1500 words)"
                                        formControlName="description"></textarea>
                                    <!-- <div *ngIf="loginForm.get('description')?.invalid && (loginForm.get('description')?.touched || loginForm.get('description')?.dirty)"
                                        class="error-message">
                                        Description is required.
                                    </div> -->
                                </div>
                            </div>
                            <div class="customwidth position-relative mt-3">
                                <button type="submit" *ngIf="add" class="loginbtn" [disabled]="loginForm.invalid">Post
                                    Enquiry</button>
                                <button type="submit" *ngIf="update" class="loginbtn"
                                    [disabled]="loginForm.invalid">Update
                                    Enquiry</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>