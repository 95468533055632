<div class=" sidenavdata ">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid p-0">
      <div class="list d-sm-flex flex-sm-column">
        <ul class="navbar-nav  flex-column w-100">
          <li class="nav-item" *ngFor="let item of itemsList">
            <a class="nav-link px-lg-3 px-1  py-3" aria-current="page" [routerLink]="['/main/profile',item.pathModule]"
            [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active-item">
              <img src="{{item.img}}" class="" alt=""> {{item.list}}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>