import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-purchase-delivery-milestone',
  templateUrl: './add-purchase-delivery-milestone.component.html',
  styleUrls: ['./add-purchase-delivery-milestone.component.scss']
})
export class AddPurchaseDeliveryMilestoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
