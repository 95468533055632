import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { DataService } from 'src/app/services/data.service';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
import { ValidationsService } from 'src/app/services/validators/validator';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from "src/app/auth/login/login.component";
import { PhoneOtpVarificationComponent } from '../phone-otp-varification/phone-otp-varification.component';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  loginForm: any;
  countnumber: any = "";
  submitted: boolean = false;
  number: any;
  constructor(private fb: UntypedFormBuilder, public validators: ValidationsService,
    private service: ApiServicesService,
    private router: Router,
    private storageService: LocalStorageProvider,
    private dataService: DataService,
    private commonService: CommonService,
    private toastr: ToastrService, public dialogRef: MatDialog,
    private dialogs: MatDialogRef<ForgotPasswordComponent>, private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      country_code: ['+91'],
      phone_no: [Number(''), Validators.required],

    });
  }

  onOtpSend(post: any) {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.submitted = false;
      // console.log(post);
      this.service.post(post, `${API_ROUTES.Account.otpSendPhone}`, {}).pipe().subscribe((res) => {
        if (res.success_code == 201) {
          // localStorage.setItem('forgotPhone', this.loginForm.value.phone_no)
          localStorage.setItem('forgotExpToken', res.result.expToken);
          localStorage.setItem('refresh_token', res.result.expToken);
          // this.dataService.setData('loginPhone', post.phone_no);
          // this.dataService.setData('otp', res.result.bypass_otp);
          this.dialogRef.closeAll();

          // Then open the OTP dialog and capture the MatDialogRef
          const otpDialogRef = this.dialog.open(PhoneOtpVarificationComponent, {
            maxHeight: '100vh',
            width: '800px',
            data: {
              type: 3,
              phoneNumber: post.phone_no
            }
          });
          // this.router.navigate([RouteConstant.PhoneOtp], { queryParams: { type: 3 } })
        }
      }, (error) => {
        // this.toastr.error(error.message) 
        // this.toastr.error(error.result.error)
        this.toastr.error(this.commonService.returnErrorMessage(error));
      }
      )
    }
  }
  Login() {
    this.dialogs.close();
    const screenWidth = window.innerWidth;
    let isSmallScreen = true;
    if (screenWidth <= 1440) {
      isSmallScreen = true;
    }
    const logindialogRef = this.dialogRef.open(LoginComponent, {
      maxHeight: '100vh',
      width: isSmallScreen ? '900px' : '900px',
    })

  }

  navigateBack() {
    this.commonService.goBack();
  }
  closeDialog(): void {
    this.dialog.closeAll(); // Pass data when closing
  }
}

