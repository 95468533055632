import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,) { 
   
  }
testimonialList:any;
  ngOnInit(): void {
  this.testimonialList=this.data.data[0]
  }

}
