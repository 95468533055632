import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostPublishComponent } from '../post-publish/post-publish.component';

@Component({
  selector: 'app-edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.scss']
})
export class EditPopupComponent implements OnInit {
  open: any;

  constructor(private dialog:MatDialog, public dialogRef: MatDialogRef<EditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }
  Cancel(){
    localStorage.setItem('dialog','remove');
    // localStorage.removeItem('dialog')
    // this.dialogRef.close( {result:false });
  }
}
