import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-field',
  templateUrl: './add-field.component.html',
  styleUrls: ['./add-field.component.scss']
})
export class AddFieldComponent implements OnInit {
  open: any;
  addCustomField: FormGroup;
  submitted: boolean = false;
  constructor(public dialogRef: MatDialogRef<AddFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder) {
    this.addCustomField = this.fb.group({
      new_field: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    // this.addCustomField = this.fb.group({
    //   fieldlabel:['',Validators.required]
    // })
  }

  get f() {
    return this.addCustomField.controls;
  }

  // addfield(){ 
  //   if(this.addCustomField.valid) {
  //     if(this.addCustomField.controls.new_field.value!=""&&this.addCustomField.controls.new_field.value!=''&&this.addCustomField.controls.new_field.value!=null&&this.addCustomField.controls.new_field.value!=undefined){
  //       this.dialogRef.close({ event: 'close', data: this.addCustomField.controls.new_field.value });
  //     }else{
  //       this.dialogRef.close({ event: 'close', data: null });
  //     }
  //   }else {
  //     this.submitted = true;
  //   }
  // }

  //modified
  // addfield(event: Event) {
  //   event.preventDefault(); // Prevent the default form submission
  //   // Performing custom validation 
  //   const newFieldControl = this.addCustomField.controls['new_field'];

  //   if (newFieldControl.valid) {
  //     const fieldValue = newFieldControl.value.trim();

  //     if (fieldValue !== '') {
  //       this.dialogRef.close({ event: 'close', data: fieldValue });
  //     } else {
  //       newFieldControl.setErrors({ 'required': true });
  //     }
  //   } else {
  //     this.submitted = true;
  //   }
  // }
  //modified  

  addfield(event: Event) {
    event.preventDefault(); // Prevent the default form submission
    const newFieldControl = this.addCustomField.controls['new_field'];

    if (newFieldControl.valid) {
      const fieldValue = newFieldControl.value.trim();

      if (/^[a-zA-Z0-9\s]+$/.test(fieldValue)) {
        if (fieldValue !== '') {
          this.dialogRef.close({ event: 'close', data: fieldValue });
        } else {
          newFieldControl.setErrors({ 'required': true });
        }
      } else {
        newFieldControl.setErrors({ 'invalidCharacters': true });
      }
    } else {
      this.submitted = true;
    }
  }
}
