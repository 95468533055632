import { Component, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConst } from 'src/app/core/_constants/app.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { ValidationsService } from 'src/app/services/validators/validator';
import { PostPublishComponent } from 'src/app/shared/dialogs/post-publish/post-publish.component';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
// import { ConfirmPasswordValidator } from './confirm-password.validator';
import { DeviceUUID } from 'device-uuid';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from 'src/app/services/data.service';
// import { SocialAuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SignInService } from 'src/app/services/sign-in.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelect } from '@angular/material/select';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { ActivatedRoute } from '@angular/router';
import { LogoutComponent } from 'src/app/shared/dialogs/logout/logout.component';
@Component({
  selector: 'app-edit-enquiry',
  templateUrl: './edit-enquiry.component.html',
  styleUrls: ['./edit-enquiry.component.scss']
})
export class EditEnquiryComponent implements OnInit {
  linkedInCredentials = {
    clientId: "77zfe6wdghbp0p",
    redirectUrl: "https://raftaarr.com/login",
    scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
  };
  myProfile: boolean = true;
  @ViewChild('select') private select: MatSelect;
  loginForm: any;
  submitted: boolean = false;
  countnumber: any = "";
  industry: any;
  business_activity: any = [];
  docList = [];
  test: any;
  categories: any;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  uuid = new DeviceUUID().get();
  deviceInfo = this.deviceService.getDeviceInfo();
  currentDate = new Date();
  password: any
  show = false;
  confirmshow: boolean = false;
  confirmpassword: any
  businessActivityOpened: any;
  selectedBusinessActivity: any = [];
  showPhoneNumberWarning: boolean;
  constructor(private fb: UntypedFormBuilder, public validators: ValidationsService,
    private service: ApiServicesService,
    private router: Router,
    private storageService: LocalStorageProvider,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private dataService: DataService,public dialogRef: MatDialog,
    // private authService:SocialAuthService,
    private sign: SignInService, private route: ActivatedRoute, private cdRef: ChangeDetectorRef, private toastr: ToastrService) {
  }
  enquiryId: any;
  data: any;
  update: boolean = false;
  add: boolean = true;
  closeDialog(): void {
    this.dialog.closeAll(); // Pass data when closing
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.enquiryId = params['id'];
      // console.log(this.enquiryId, 'this.enqq');
      if (this.enquiryId) {
        this.update = true;
        this.add = false;
        setTimeout(() => {
          this.service.post({ id: this.enquiryId }, `${API_ROUTES.Account.getEnquiryDetail}`, '').pipe().subscribe((res) => {
            this.data = res.result;
            for (const key in this.data) {
              if (this.data.hasOwnProperty(key) && key !== 'Industry' && key !== 'Brand' && key !== 'SubCategory') {
                if (this.loginForm.contains(key)) {
                  this.loginForm.get(key)?.setValue(this.data[key]);
                }
              }
            }
            if (this.data.Industry) {
              this.loginForm.get('industry_id')?.setValue(this.data.Industry.id);
            }
            if (this.data.Brand) {
              this.loginForm.get('brand_id')?.setValue(this.data.Brand.id);
            }
            if (this.data.SubCategory) {
              this.fetchIndustriesByCategory(res.result.Industry.id);
              this.onIndustryChange(res.result.SubCategory.id);
            }
            // Updating form values...
            this.cdRef.detectChanges();  // Manually trigger change detection
          });
        });
      } else {
        this.update = false;
        this.add = true;
      }
    });
    // }
    // this.route.queryParams.subscribe(params => {
    //   this.enquiryId = params['id'];
    //   console.log(this.enquiryId, 'this.enqq');
    //   if (this.enquiryId) {
    //     this.update = true;
    //       this.add = false;
    //     setTimeout(() => {

    //       this.service.post({ id: this.enquiryId }, `${API_ROUTES.Account.getEnquiryDetail}`, '').pipe().subscribe((res) => {
    //         this.data = res.result;
    //         for (const key in this.data) {
    //           if (this.data.hasOwnProperty(key) && key !== 'Industry' && key !== 'Brand' && key !== 'SubCategory') {
    //             if (this.loginForm.contains(key)) {
    //               this.loginForm.get(key)?.setValue(this.data[key]);
    //             }
    //           }
    //         }
    //         if (this.data.Industry) {
    //           this.loginForm.get('industry_id')?.setValue(this.data.Industry.id);
    //         }
    //         if (this.data.Brand) {
    //           this.loginForm.get('brand_id')?.setValue(this.data.Brand.id);
    //         }
    //         if (this.data.SubCategory) {
    //           this.fetchIndustriesByCategory(res.result.Industry.id);
    //           this.onIndustryChange(res.result.SubCategory.id);
    //         }
    //       });
    //     });
    //   } else {
    //     this.update = false;
    //     this.add = true;
    //   }
    // });
    this.getIndustry();
    this.getBrand();
    this.loginForm = this.fb.group({
      full_name: ['', []],
      email: ['', []],
      country_code: ["+91"],
      phone_no: ["", []],
      category_id: [null, []],
      service_name: ['', []],
      timing_from: ['', []],
      timing_to: ['', []],
      quantity: [null, []],
      company_name: ['', []],
      product_name: ['', []],
      location: ['', []],
      loan_required: ['no', []],
      brand_id: [null, []],
      description: ['', []],
      industry_id: [null, []],
      req_area: [null, []]
    }
    );
  }
  onToggleChange(event: Event, controlName: string): void {
    const checkbox = event.target as HTMLInputElement;
    const control = this.loginForm.get(controlName);

    if (control) {
      control.setValue(checkbox.checked ? 'yes' : 'no');
      // Update the form control value based on the checkbox state
      // console.log(`${controlName} updated to:`, control.value);
    } else {
      console.error(`${controlName} not found in form`);
    }
  }
  // ngOnInit(): void {
  //   this.route.queryParams.subscribe(params => {
  //     this.enquiryId = params['id'];
  //     console.log(this.enquiryId,'this.enqq')
  //     if (this.enquiryId) {
  //       this.update = true;
  //       this.add = false;
  //       this.service.post({ id: this.enquiryId }, `${API_ROUTES.Account.getEnquiryDetail}`, '').pipe().subscribe((res) => {
  //         this.data = res.result;
  //         for (const key in this.data) {
  //           if (this.data.hasOwnProperty(key) && key !== 'Industry' && key !== 'Brand' && key !== 'SubCategory') {
  //             if (this.loginForm.contains(key)) {
  //               this.loginForm.get(key)?.setValue(this.data[key]);
  //             }
  //           }
  //         }
  //         // Set Industry and Brand values separately since they are objects
  //         if (this.data.Industry) {
  //           this.loginForm.get('industry_id')?.setValue(this.data.Industry.id);
  //         }
  //         if (this.data.Brand) {
  //           this.loginForm.get('brand_id')?.setValue(this.data.Brand.id);
  //         }
  //         if (this.data.SubCategory) {
  //           // console.log(res.result.SubCategory.id);
  //           // this.loginForm.get('category_id')?.setValue(res.result.SubCategory.id);
  //           this.fetchIndustriesByCategory(res.result.Industry.id);
  //           this.onIndustryChange(res.result.SubCategory.id);
  //         }
  //         // console.log(this.loginForm.value);
  //       })
  //     } else {
  //       this.update = false;
  //       this.add = true;
  //     }
  //     // console.log('Received ID in edit-enquiry:', this.enquiryId);
  //   });
  //   this.getIndustry();
  //   this.getBrand();
  //   this.loginForm = this.fb.group({
  //     full_name: ['', []],
  //     email: ['', []],
  //     category_id: [null, []],
  //     service_name: ['', []],
  //     timing_from: ['', []],
  //     timing_to: ['', []],
  //     quantity: [null, []],
  //     company_name: ['', []],
  //     product_name: ['', []],
  //     location: ['', []],
  //     loan_required: ['no', []],
  //     brand_id: [null, []],
  //     description: ['', []],
  //     industry_id: [null, []],
  //     req_area: [null, []]
  //   }
  //   );
  // }
  loan = [
    { id: 'yes', title: 'Yes' },
    { id: 'no', title: 'No' },
  ];
  filteredCat = this.loan;
  categoryListData: any = [];
  subCategoryList: any = [];
  @ViewChild('category1Ref', { static: false }) categoryRef: any; // Change this to match your input template variabl

  // onSelectOpenClose(isOpen: boolean, type: string): void {
  //   if (!isOpen) {
  //     if (type === 'category' && this.categoryRef) {
  //       this.categoryRef.nativeElement.value = ''; // Clear the input field if it exists
  //       this.category = this.category; // Reset to the full list
  //     }
  //   }
  // }


  subCategory(event: any) {
    // this.rawMaterialForm.patchValue({
    //   raw_material_c2_id: ''
    // })
    // this.subCategorySet(event);
  }


  updateConfirmPasswordValidation() {
    const passwordControl = this.loginForm.get('password');
    const confirmPasswordControl = this.loginForm.get('confirm_password');

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ 'passwordsNotMatch': true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  }

  get f() {
    return this.loginForm.controls;

  }
  confirm() {
    if (this.confirmpassword === 'password') {
      this.confirmpassword = 'text';
      this.confirmshow = true;
    } else {
      this.confirmpassword = 'password';
      this.confirmshow = false;
    }
  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  enId: any;
  onSubmit(post: any) {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.enId = this.enquiryId;
      // console.log(this.enId, 'enquiry from');
      if (this.enId) {
        this.update = true;
        this.add = false;
        this.service.put(post, { id: this.enId }, `${API_ROUTES.Account.updateEnquiry}`).pipe().subscribe((res) => {
          if (res.success) {
            // console.log('form updated');
            this.toastr.success(res.message);
            this.loginForm.reset();
            this.router.navigate(['/main/profile/enquiry']);
          } else {
            this.toastr.error(res.message);
            // console.log(res.message);
          }
        },
          (error) => {
            this.toastr.error(error.result.error);
            this.router.navigate(['/main/profile/enquiry']);
            // this.registered(error.message);
          })
      } else {
        this.update = false;
        this.add = true;
        this.service.post(post, `${API_ROUTES.Account.addEnquiries}`, {}).pipe().subscribe((res) => {
          if (res.success) {
            this.closeDialog();
            this.dialogRef.open(LogoutComponent, {
              maxHeight: '100vh',
              width: '50%',
              data: {
                img: '../assets/images/new_img/Group.svg',
                heading: 'Your Enquiry has been Submitted',
                para: 'We will get back to you as soon as possible.'
              }
            })
            // this.toastr.success(res.message);
            this.router.navigate(['/main/profile/enquiry']);
            // console.log('form submitted');
            this.loginForm.reset();
          } else {
            this.toastr.error(res.message);
            // console.log(res.message);
          }
        },
          (error) => {
            this.toastr.error(error.result.error);
            // this.registered(error.message);
          })
      }
    }
  }
  onCategoryChange(selectedCategoryId: string) {
    if (selectedCategoryId) {
      this.fetchIndustriesByCategory(selectedCategoryId);
    }
  }

  // onCategoryChange(selectedCategoryId: string) {
  //   this.fetchIndustriesByCategory(selectedCategoryId);
  // }

  // Fetch industries based on the selected category
  machine: boolean = false;
  fetchIndustriesByCategory(categoryId: string) {
    if (categoryId == '0627f25d-6d56-4237-b561-d0ea9830acd7') {
      this.machine = false;
    } else {
      this.machine = true;
    }
    this.service.post({ industry_id: categoryId }, `${API_ROUTES.Account.getIndustriesByCategory}`, {}).pipe().subscribe(res => {
      this.industry = res.result;
      // console.log(this.industry, 'indussss');
    }, err => {
      this.industry = [];
    });
  }

  // Reset select options when the dropdown is closed
  onSelectOpenClose(isOpen: boolean, type: string): void {
    if (!isOpen) {
      if (type === 'category' && this.categoryRef) {
        this.categoryRef.nativeElement.value = '';  // Clear input field
        this.category = this.category;  // Reset to full list
      }
    }
  }
  isJobWorkSelected: boolean = false;
  isPropertyIndustrySelected: boolean = false;
  onIndustryChange(selectedIndustryId: string) {
    // this.isJobWorkSelected = this.isJobWorkIndustry(selectedIndustryId);
    this.isPropertyIndustrySelected = this.isPropertyIndustry(selectedIndustryId);
  }
  isPropertyIndustry(industryId: string): boolean {
    const propertyIndustryId = '89e8c3d0-c9b8-4a07-a8e1-61a8eb04716d';
    return industryId === propertyIndustryId;
  }
  // Check if the selected industry is "Job Work"
  isJobWorkIndustry(industryId: string): boolean {
    const jobWorkIndustryId = '31dcdb22-05c4-4a27-8481-3afbee03e8d4';
    return industryId === jobWorkIndustryId;
  }
  category: any;
  getIndustry() {
    this.service.get({}, `${API_ROUTES.Account.getIndustry}`).pipe().subscribe((res) => {
      this.category = res.result;
      // console.log(this.industry, 'industry');
    })
  }
  brands: any;
  getBrand() {
    // let catagory = {
    //   industry_id: id
    // }
    this.service.get({}, `${API_ROUTES.Account.getBrand}`).pipe().subscribe((res) => {
      this.brands = res.result;
    })
  }

  registered(msg: any) {
    const screenWidth = window.innerWidth;
    let isSmallScreen = false;
    if (screenWidth <= 1440) {
      isSmallScreen = true;
    }

    const dialogRef = this.dialog.open(PostPublishComponent, {
      maxHeight: '100vh',
      width: isSmallScreen ? '300px' : '465px',
      panelClass: 'resetPassword',
      data: {
        img: 'assets/images/info-circle_red.svg',
        heading: msg,
        // title: 'Please check your inbox and click in the recieved link to reset a password',
        image: 'assets/images/logout_2.svg',
        btn: 'Back to login'
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/'])
      }
    });
  }

  businessActivityOpen(ev: any) {
    this.businessActivityOpened = ev;
    if (this.businessActivityOpened == false && this.selectedBusinessActivity.length > 0) {
      // this.getCategory(this.selectedBusinessActivity[0])
      // this.selectedBusinessActivity.forEach((id:any)=>{
      //   this.getIndustry(id);
      // })
    }
    else if (this.businessActivityOpened == false && this.selectedBusinessActivity.length == 0) {
      this.categories = [];
    }

  }

  fix(event: any) {
    this.selectedBusinessActivity = event.value;
    // let findFlag;
    // if(event.value.length == this.data.length){
    //   this.select.close();
    // }
    // this.docList = [];
    // event.value.forEach((val:any)=>{
    //   if(val.id!=null){
    //     let obj = this.business_activity.find((item:any) =>
    //       item.industry_name == val.industry_name
    //     );
    //       if (obj) {
    //         // findFlag = true;
    //       } else {
    //         // findFlag = false;
    //         this.business_activity.push(val);
    //         this.getCategory(val.id);

    //       }
    //   }
    // });


  }
  fixCategory(id: any) {


    this.loginForm.value.category1_id = id;

  }

  linkedIn() {
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId
      }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
  }

  passwordField(evt: any) {

    //var e = evt || window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode

    if (charCode != 32)
      return true;
    return false;
  }

  weightage() {
    let data = {
      company_name: true,
      description: false,
      address: false,
      industry_id: true,
      category1_id: true,
      categoryl5_id: false,
      gst_no: false,
      company_size: false,
      email: true,
      phone_no: true,
      total_machine: false,
      incorporated_year: false,
      state: false,
      city: false,
      lastyear_turnover: false,
      constitution_of_business: false,

    }
    this.service.post(data, `${API_ROUTES.More.weightage}`, {}).pipe().subscribe((res) => {

      if (res.success) {

      }



    },
      // (error)=>{
      //   this.toastr.error(error.result.error)
      // }
    )
  }

  public search(find: string) {
    // console.log("Current input:", find);
    const value = find.toLowerCase();
    if (value.trim() === '') {
      // this.filteredCat = this.staticlocation; // Reset to the full list if input is empty
    } else {
      // this.filteredCat = this.staticlocation.filter(category =>
      //   category.title.toLowerCase().includes(value) // No need to restrict spaces
      // );
    }
  }
}
