import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { API_ROUTES } from "src/app/core/_constants/api-route.constant";
import { ApiServicesService } from "src/app/services/apiServices/api-services.service";
import { LocalStorageProvider } from "src/app/services/storage/storage.service";
import { ValidationsService } from "../../services/validators/validator";
import { DeviceUUID } from "device-uuid";
import { DeviceDetectorService } from "ngx-device-detector";
import { DataService } from "src/app/services/data.service";
import { SignInService } from "src/app/services/sign-in.service";
import { CommonService } from "src/app/services/common.service";
import { RouteConstant } from "src/app/core/_constants/route.constant";
import { environment } from "src/environments/environment";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PhoneOtpVarificationComponent } from "../phone-otp-varification/phone-otp-varification.component";
import { SignupComponent } from "../signup/signup.component";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  inProduction = environment.production;

  linkedInCredentials = {
    clientId: "77zfe6wdghbp0p",
    redirectUrl: this.inProduction
      ? "https://web.raftaarr.com"
      : "https://devweb.raftaarr.com",
    scope: "r_liteprofile%20r_emailaddress", // To read basic user profile data and email
    clientSecret: "68tXCSC4q83ePVBI",
  };
  loginForm: any;
  emailloginForm: any;
  submitted: boolean = false;
  countnumber: any = "";
  mobilelogin: boolean = true;
  emaillogin: boolean = false;
  loginExpToken: any;
  number: any;
  useremail: any;
  uuid = new DeviceUUID().get();
  deviceInfo = this.deviceService.getDeviceInfo();
  currentDate = new Date();
  hidePassword: boolean = true;
  password: any;
  show = false;
  // emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,10}$";
  // passwordPattern= "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$"
  code: any;
  basicData: any;
  lastName: any;
  firstName: any;
  id: any;
  emailId: any;
  linkedinAccessToken: any;
  RouteConstant: any = RouteConstant;

  constructor(
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private storageService: LocalStorageProvider,
    public validators: ValidationsService,
    private service: ApiServicesService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private dataService: DataService,
    private activeRoute: ActivatedRoute,
    private commonService: CommonService,
    private sign: SignInService, private angularFireMessaging: AngularFireMessaging, private dialog: MatDialog,
    private dialogRef: MatDialogRef<LoginComponent>
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      this.code = params?.["code"];
    });
  }
  windowURL: any;
  ngOnInit(): void {
    let url = new URL(window.location.href);

    // Get the base URL
    const baseURL = `${url.protocol}//${url.host}${url.pathname}`;

    this.windowURL = baseURL.replace(/\/$/, "");
    let token = localStorage.getItem("stoken");

    localStorage.removeItem("preventback");
    this.password = "password";
    this.emailloginForm = this.fb.group({
      username: [
        "",
        [
          Validators.required,
          Validators.pattern(this.validators.regex.emailPattern),
        ],
      ],
      password: ["", [Validators.required]],
      device_token: localStorage.getItem('deviceToken'),
      // device_token: sessionStorage.getItem("deviceToken"),
      device_id: localStorage.getItem('device_Id')?.toString(),
      // device_id: sessionStorage.getItem("device_Id")?.toString(),
      device_type: this.deviceInfo.deviceType,
    });

    this.loginForm = this.fb.group({
      country_code: ["+91"],
      phone_no: ["", [Validators.required]],
    });

    if (
      !token &&
      (!this.emailloginForm.controls["username"].value ||
        !this.loginForm.controls["phone_no"].value)
    ) {
      localStorage.setItem("guestUser", "yes");
    } else {
      localStorage.removeItem("guestUser");
    }
    // this.onOtpSend(this.loginForm.value)
    if (this.code && this.code != "") {
      this.linkedInAccessToken();
    }
  }
  onClick() {
    if (this.password === "password") {
      this.password = "text";
      this.show = true;
    } else {
      this.password = "password";
      this.show = false;
    }
  }
  closeDialog(): void {
    this.dialog.closeAll(); // Pass data when closing
  }
  //Login with Email
  onSubmit(post: any) {
    if (this.emailloginForm.valid) {

      post['device_id'] = localStorage.getItem('device_Id')?.toString();
      post["device_token"] = localStorage.getItem("deviceToken");
      this.service
        .post(post, `${API_ROUTES.Account.loginwithEmail}`, {})
        .pipe()
        .subscribe(
          (response) => {

            if (response.success_code == 201) {
              this.dataService.setCompanyInfo(response.result.CompanyInfo);
              if (!response.result.isCompanyInterest)
                localStorage.setItem(
                  "companyInterest",
                  response.result.isCompanyInterest
                );
              localStorage.removeItem("guestUser");
              localStorage.setItem('window', window.location.href)
              localStorage.removeItem("currentTime");

              localStorage.setItem(
                "stoken",
                response.result.token
              );

              localStorage.setItem(
                "refresh_token2",
                response.result.refresh_token
              );
              localStorage.setItem(
                "refresh_token",
                response.result.refresh_token
              );
              if (localStorage.getItem('product-url')) {
                localStorage.setItem("UserId", response.result.id);
              }
              this.dialogRef.close();
              this.dialogRef.afterClosed().subscribe(() => {
                this.router.navigate([RouteConstant.mainhome]);
                window.location.reload();
              });
              if (response.result.step == 1) {
                this.router.navigateByUrl(
                  "/" + RouteConstant.mainhome
                );

              } else if (response.result.step == 0) {
                this.commonService.signIn(
                  response.result.email,
                  response.result.email
                );

                let url = this.storageService.getItem("product-url");

                if (url) {
                  if (url.includes("http://localhost:4200/")) {
                    const modifiedUrl = url?.replace(
                      "http://localhost:4200/",
                      ""
                    );
                    this.router.navigateByUrl(modifiedUrl);
                  } else if (url.includes("https://devweb.raftaarr.com/")) {
                    const modifiedUrl = url?.replace(
                      "https://devweb.raftaarr.com/",
                      ""
                    );
                    this.router.navigateByUrl(modifiedUrl);
                  } else if (url.includes("https://raftaarr.com/")) {
                    const modifiedUrl = url?.replace(
                      "https://raftaarr.com/",
                      ""
                    );
                    this.router.navigateByUrl(modifiedUrl);
                  }
                } else {
                  localStorage.setItem("UserId", response.result.id);
                  localStorage.setItem("route", "yes");
                  this.router.navigateByUrl(
                    "/" + RouteConstant.mainhome
                  );
                }
              }
            }
          },
          (error) => {
            this.toastr.error(error);
            this.toastr.error(this.commonService.returnErrorMessage(error));
          }
        );
    } else {
      this.toastr.error('Invalid Form');
      this.submitted = false;;
    }
  }

  //Login with phone number

  onOtpSend(post: any) {
    if (this.storageService.getItem("currentTime")) {
      this.otpCount(post);
    } else {
      if (this.loginForm.valid) {
        // this.service
        //   .post(post, `${API_ROUTES.Account.otpSendPhone}`, {})
        //   .subscribe(
        //     (res) => {
        //       console.log(res, 'otp');
        //       this.loginExpToken = res.result.expToken;

        //       localStorage.setItem("expToken", this.loginExpToken);
        //       localStorage.setItem("refresh_token", this.loginExpToken);
        //       localStorage.setItem('window', window.location.href)


        //       this.dataService.setData("loginPhone", post["phone_no"]);
        //       if (res.success) {
        this.openOtpDialog(post);


        //     }
        //   },
        //   (error) => {

        //     this.toastr.error(this.commonService.returnErrorMessage(error));
        //   }
        // );
      } else {
        this.submitted = true;
      }

    }
  }
  openOtpDialog(post: any) {
    // Close the login dialog first
    this.dialogRef.close();  // Close the login dialog when OTP is sent
    // Then open the OTP dialog and capture the MatDialogRef
    const otpDialogRef = this.dialog.open(PhoneOtpVarificationComponent, {
      maxHeight: '100vh',
      width: '800px',
      data: {
        type: 2,
        phoneNumber: post.phone_no
      }
    });
  }

  otpCount(data: any) {
    let currentTime: any = new Date().getTime();
    let pastTime: any = this.storageService.getItem("currentTime");
    let diff = currentTime - pastTime;

    this.loginOtpCounter(diff, data);
  }

  loginOtpCounter(diff: any, data: any) {
    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    if (hh == 0 && mm < 15 && mm != 14) {
      this.toastr.error("Please try again after " + (15 - mm) + " mins");
    } else if (hh == 0 && mm == 14) {
      this.toastr.error("Please try again after 1 min");
    } else {
      localStorage.removeItem("currentTime");
      this.onOtpSend(data);
    }
  }

  onClickRoute() {
    this.dialogRef.close();  // Close the login dialog when OTP is sent

    // Then open the OTP dialog and capture the MatDialogRef
    const forgetDialogRef = this.dialog.open(ForgotPasswordComponent, {
      maxHeight: '100vh',
      width: '800px',
    });

  }

  loginwithmobile() {
    this.mobilelogin = !this.mobilelogin;
    this.emaillogin = !this.emaillogin;
  }

  loginwithemail() {
    localStorage.setItem('loginType', '2')
    this.emaillogin = !this.emaillogin;
    this.mobilelogin = !this.mobilelogin;
    if (this.mobilelogin == true) {
      this.submitted = false;
    }
  }

  googleSignin() {
    let deviceToken = localStorage.getItem('deviceToken');

    let deviceId = localStorage.getItem('device_Id')?.toString();

    this.sign.GoogleAuth(1, deviceToken, deviceId, this.deviceInfo.deviceType);
  }

  facebookSignin() {
    let deviceToken = localStorage.getItem('deviceToken');

    let deviceId = localStorage.getItem('device_Id')?.toString();

    this.sign.FacebookAuth(
      2,
      deviceToken,
      deviceId,
      this.deviceInfo.deviceType
    );
  }

  linkedIn() {

    let url = "";

    if (this.windowURL == "https://raftaarr.com") {
      url = "https://raftaarr.com/login";
    } else {
      url = "https://devweb.raftaarr.com/login";
    }
    // url = "https://raftaarr.com/login"; //For production
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId}&redirect_uri=${url}&scope=${this.linkedInCredentials.scope}`;
  }

  linkedInAccessToken() {

    let type;
    if (this.windowURL == "https://raftaarr.com") {
      type = "https://raftaarr.com/login";
    } else {
      type = "https://devweb.raftaarr.com/login";
    }
    // type = "https://raftaarr.com/login";  //For Production
    this.sign.linkedInAccessToken(this.deviceInfo.deviceType, this.code, type);
  }


  isNumberKey(evt: any) {

    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  guestLogin() {

    this.storageService.setItem("guestUser", "yes");
    this.router.navigate(["/" + RouteConstant.mainhome]);
  }

  onClickRouteSignUp() {
    this.dialogRef.close();  // Close the login dialog when OTP is sent

    // Then open the OTP dialog and capture the MatDialogRef
    const otpDialogRef = this.dialog.open(SignupComponent, {
      maxHeight: '100vh',
      width: '800px',
    });
  }

}
