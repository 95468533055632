import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
@Injectable({
  providedIn: 'root'
})
export class ApiServicesService {

  getBaseUrl(): string {
    return environment.domUrls;  // Get the base URL from environment file
  }

  getProducts //       this.loader.stop();
    () {
    throw new Error("Method not implemented.");
  }
  editData(data: any, it: any) {
    throw new Error('Method not implemented.');
  }
  // private apiUrl = 'hhttps://dummyjson.com/products'; // Example API endpoint
  constructor(private http: HttpClient, private toastr: ToastrService, private loader: NgxUiLoaderService, private storageService: LocalStorageProvider) { }

  post(payload: any, url: string, parameter: any): Observable<any> {
    //    this.loader.start();
    let params = new HttpParams()
    params = params.appendAll(parameter)
    return this.http.post<any>(url, payload, { params: params }).pipe(
      catchError(err => {
        this.loader.stop();
        return throwError(err.error);
      }),
      map(resp => {
        this.loader.stop();
        return resp
      }),
    )
  }

  tt: any;

  async postWithFetch(formData: FormData, url: string, parameters: any = {}) {
    // Create a query string from parameters (if any)
    const queryString = new URLSearchParams(parameters).toString();
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    this.tt = localStorage.getItem('stoken');
    try {
      // Perform the POST request using fetch API
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'stoken': this.tt,
        }
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`File upload failed: ${response.statusText}`);
      }

      // Parse the response (assuming it's JSON)
      const data = await response.json();
      // console.log('Response from server:', data.result);
      if (data.success) {
        if (data.result) {
          // Return the response data or whatever structure is needed
          return data.result.urls[0];
        }
      } else {
        console.log("No file uploaded.")
      }

    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;  // Rethrow the error for further handling if needed
    }
  }

  put(payload: any, queryParams: any, url: string): Observable<any> {
    let params = new HttpParams()
    params = params.appendAll(queryParams)
    return this.http.put<any>(url, payload, { params: params }).pipe(
      catchError(err => {

        this.loader.stop();
        return throwError(err.error);
      }),
      map(resp => {
        this.loader.stop();
        return resp
      }),
    )
  }

  get(payload: any, url: any, stoken?: string): Observable<any> {
    // this.loader.start();

    let params = new HttpParams();
    params = params.appendAll(payload);

    // Set headers
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${stoken}` // Assuming the token is a Bearer token
    });

    return this.http.get(url, { params: params, headers: headers }).pipe(
      catchError(err => {
        // this.loader.stop();
        return throwError(err.error);
      }),
      map((response: any) => {
        // this.loader.stop();
        return response;
      })
    );
  }


  // get(payload: any, url: any): Observable<any> {
  //   this.loader.start();
  //   let params = new HttpParams()
  //   params = params.appendAll(payload)
  //   return this.http.get(url, { params: params }).pipe(
  //     catchError(err => {

  //       this.loader.stop();
  //       return throwError(err.error);
  //     }),
  //     map((response: any) => {
  //       this.loader.stop();
  //       return response;
  //     }),

  //   )
  // }

  delete(payload: any, url: any): Observable<any> {
    // this.loader.start();

    // Create a new HttpParams instance to append the parameters
    let params = new HttpParams();

    // Ensure that we are not appending undefined values
    // if (payload.productId) {
    //   params = params.append('product_id', payload.productId);
    // } else {
    //   console.error("productId is undefined in payload.");
    //   this.toastr.error("Product ID is missing in request.");
    //   this.loader.stop();
    //   return throwError("Product ID is missing.");
    // }
    params = params.appendAll(payload)
    return this.http.delete(url, { params: params }).pipe(
      catchError(err => {
        // this.loader.stop();
        return throwError(err.error);  // Returning the error as a stream
      }),
      map((response: any) => {
        // this.loader.stop();
        return response;  // Return the response as a stream
      }),
    );
  }


  //  refreshToken(){
  //   const url = `${environment.baseURl}/v1/company/refresh_token`;
  // }

  refreshToken() {
    const token = localStorage.getItem('stoken')
    const url = `${environment.baseURl}/v1/company/refresh-token`;
    const payload = {
      stoken: token
    };
    return this.put(payload, {}, url);
  }
  scrollToToast() {
    // Find the toast container or the last toast message
    const toastElement = document.querySelector('.toast-bottom-right');

    // Ensure the toast is visible in the viewport without scrolling up
    if (toastElement) {
      toastElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest', // Scroll to the nearest viewport edge, based on where the user is
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }


}
