<div class="row">
    <div class=" col-md-12 d-block pb-0 text-end">
        <button class="button-cls" (click)="closeDialog()">
            <img src="assets/images/arrow_close.svg">
        </button>
    </div>
</div>
<div class="row bg-white d-flex justify-content-center mainnnn">
    <!-- <div class="col-md-6 p-0  sideimg">
        <div class="signupback">
            <img src="assets/images/auth.png">
        </div>   
    </div> -->
    <form class="col-md-12 text-center" (submit)="onOtpSend(loginForm.value)" [formGroup]="loginForm">
        <h4 class=" fp" style="display: flex ;
            align-items: center;
            justify-content: center; ">Forgot Password</h4>

        <!-- login with phone -->
        <div class="phonedetail d-grid ">
            <div class="w-100 position-relative  mt-2">
                <p class="position-absolute c_input border-end pe-2 me-2">+91</p>
                <p class="counternumber px-2 text-center"><span
                        *ngIf="countnumber.length<=9 && countnumber.length>0">0</span>{{countnumber.length}}/10
                </p>
                <input type="text" class="form-control countrycode" formControlName="phone_no" maxlength="10"
                    [(ngModel)]="number" appDigitOnly pattern="^[1-9][0-9]*$" (keydown)="validators.avoidSpace($event)"
                    [(ngModel)]="countnumber" placeholder="Enter Phone Number">
                <!-- <small *ngIf="(submitted && loginForm.get('phone_no').invalid)">                            Phone Number is required
                        </small>  -->
                <small *ngIf="submitted && loginForm.controls['phone_no'].invalid" class="alert">
                    <small *ngIf="submitted && loginForm.controls['phone_no'].errors.required">
                        Phone Number is required
                    </small>

                </small>
            </div>
        </div>
        <div class="text-center resetbtn mt-4">
            <button type="submit" class="loginbtn">Continue to reset</button>
        </div>
        <div class="text-center mt-3">
            <p class="noaccount">Remember Password?
                <button type="button" class="createaccount" [title]="'Log In'" (click)="Login()">Login</button>
            </p>
        </div>
    </form>
</div>