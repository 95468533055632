<!--
<div class="profile  m-0">
  <div class="d-flex align-items-start  aboutprofile col-lg-2 p-0 ">
    <div class="nav flex-column nav-pills " >
      <button class="nav-link active d-flex justify-content-between"  (click)="about()" >About
        <img src="assets/images/arrow-right_3.svg" class="arrow">
      </button>
      <hr class="m-0">
      <button class="nav-link d-flex justify-content-between" (click)="projects()"   >Projects
        <img src="assets/images/arrow-right_3.svg" class="arrow">
      </button>
      <hr class="m-0">
      <button class="nav-link d-flex justify-content-between" (click)="product()">Featured
        Products
        <img src="assets/images/arrow-right_3.svg" class="arrow">
      </button>
      <hr class="m-0">
      <button class="nav-link d-flex justify-content-between" (click)="instruments()" >Instruments
        <img src="assets/images/arrow-right_3.svg" class="arrow">
      </button>
      <hr class="m-0">
    </div>
  </div>
  <div  class="d-flex align-items-center justify-content-center ">
    <button class="btn add-new mt-4 mx-4 position-absolute" (click)="addNew()">
      <img src="../assets/images/add-circle_2.svg" class="me-2"> Add New</button>
  </div>
</div>

 -->


<div class=" sidenavdata ">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid p-0">
      <!-- <div class="d-flex justify-content-between align-items-center mob-view">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <img class="user" src="assets/images/top_logo.png" alt="">
          <div class="dropdown user ms-5">
            <div class="d-flex align-items-center">
              <img class="rounded-circle dropdown-toggle" type="button" data- bs-toggle="dropdown" aria-expanded="false"
                width="35px" height="35px" src="assets/images/user-solid.svg">
              <img src="assets/images/drop.png" (click)="showdropdown()">
            </div>
            <ul class="dropdown-menu border-0 p-3" [ngClass]="isShow ? 'show':'hide'">
              <li>
                <a class="dropdown-item d-flex align-items-start" *ngFor="let user of users" >
                  <img [src]="user.img">
                  <div class="ms-3">
                    <h4>{{user.heading}}</h4>
                    <p>{{user.para}}</p>
                  </div>
                </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-start" (click)="logout()" >
                    <img src="assets/images/logout.svg">
                    <div class="ms-3">
                      <h4>Logout</h4>
                      <p>Pellentesque vivamus nulla est</p>
                    </div>
                  </a>
                  </li>
            </ul>
          </div>
        </div> -->
      <div class="list">
        <ul class="navbar-nav  flex-column w-100">
          <!-- {{itemsList | json}} -->
          <li class="nav-item" *ngFor="let item of itemsList">
            <a class="nav-link px-lg-3 px-1  py-3" aria-current="page" [routerLink]="['/main/profile',item.pathModule]"
              [routerLinkActiveOptions]={exact:true} routerLinkActive="active-item">
              <img src="{{item.img}}" class="" alt=""> {{item.list}}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- <hr>
    <div class="px-4 py-3 mb-5">
      <button class="px-4 py-2 border-0 helpuser"> Help</button>
  </div> -->
  <!-- <div *ngIf="myProfile" class="d-flex justify-content-lg-center justify-content-start align-items-center ">
    <button class="px-4  d-flex align-items-center add-New mt-2  mb-lg-0 mb-3 ms-lg-0 ms-3" (click)="addNew()">
      <img class="me-2" src="assets/images/add-circle_2.svg"> Add New</button>
  </div> -->
</div>