<div class="modal-header pb-0 p-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
       <img src="assets/images/close 1.svg" width="40px" height="40px">
    </button>
</div>
<div class="modal-body pt-0">
    <h1 class="pt-2">Add New</h1>
        <p>Choose one option that you would 
            like to continue with </p>
            <img src="assets/images/Website_Content.svg">
</div>
<div class="modal-footer d-flex mt-3 flex-nowrap justify-content-sm-between justify-content-center">
    <div class="text-center mb-3 ms-2 me-2 " [routerLink]="[addPostNavigation]" [queryParams]="{type: 'new'}" [mat-dialog-close]="false">
        <img src="assets/images/posticon.svg">
        <p class="mb-0 mt-2"  > Post</p>
    </div>
    <div class="text-center mb-3 me-2 ms-2" [routerLink]="[selectRequirement]" [mat-dialog-close]="false">
        <img src="assets/images/req.svg">
        <p class="mb-0 mt-2"> Requirement</p>
    </div>
  
    <!-- <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="['/main/profile/newFeature']" [mat-dialog-close]="false">
        <img src="assets/images/feature.svg">
        <p class="mb-0 ms-3"> Featured Product</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="['/main/profile/newProjects']" [mat-dialog-close]="false">
        <img src="assets/images/projects.svg">
        <p class="mb-0 ms-3"> Projects</p>
    </div>
    <div class="text-center mb-3 me-2 d-flex align-items-center"  [routerLink]="['/main/profile/newMachine']" [mat-dialog-close]="false">
        <img src="assets/images/machine_1.svg">
        <p class="mb-0 ms-3"> Instruments/Machine</p>
    </div>
    <div class="text-center me-2 d-flex align-items-center" [mat-dialog-close]="false">
        <img src="assets/images/feature.svg">
        <p class="mb-0 ms-3"> Marketplace Products</p>
    </div> -->
</div>