<div class="row">
    <div class=" col-md-12 d-block pb-0 text-end">
        <button class="button-cls" (click)="closeDialog()">
            <img src="assets/images/arrow_close.svg">
        </button>
    </div>
</div>
<div class="row m-0 container-fluid p-0 d-flex justify-content-xl-between justify-content-center bg-color mainnn">
    <!--  <div class="col-md-6 p-0  sideimg">
          <div class="signupback">
              <img src="assets/images/auth.png">
          </div>
      </div> -->
    <div class="col-md-12  reset text-center">
        <form (submit)="onSubmit()" class="text-center" *ngIf="!changepassword">
            <h4 class="mt-3">Log In to Your Account</h4>
            <p class="mt-5 formabout mb-2 d-flex justify-content-center">
                A One Time Password has been sent to your Mobile No.<span class="d-flex phonedata mt-0"
                    [formGroup]="otpForm">
                    <span form>+91 {{loginContact||forgotContact}}</span>
                    <p *ngIf="type != 4" class="ms-1 cursor"><img src="assets/images/new_img/edit2.svg" alt="" class="">
                    </p>
                </span>
            </p>
            <div>
                <div class="d-flex otpnumbers flex-column ">
                    <div class="otp-inputs">
                        <input type="text" maxlength="1" [(ngModel)]="otp[0]" [ngModelOptions]="{standalone: true}"
                            (input)="onInputChange(0)" [class.invalid]="submitted && !otp[0]" #otp1 />
                        <input type="text" maxlength="1" [(ngModel)]="otp[1]" [ngModelOptions]="{standalone: true}"
                            (input)="onInputChange(1)" [class.invalid]="submitted && !otp[1]" #otp2 />
                        <input type="text" maxlength="1" [(ngModel)]="otp[2]" [ngModelOptions]="{standalone: true}"
                            (input)="onInputChange(2)" [class.invalid]="submitted && !otp[2]" #otp3 />
                        <input type="text" maxlength="1" [(ngModel)]="otp[3]" [ngModelOptions]="{standalone: true}"
                            (input)="onInputChange(3)" [class.invalid]="submitted && !otp[3]" #otp4 />
                        <input type="text" maxlength="1" [(ngModel)]="otp[4]" [ngModelOptions]="{standalone: true}"
                            (input)="onInputChange(4)" [class.invalid]="submitted && !otp[4]" #otp5 />
                        <input type="text" maxlength="1" [(ngModel)]="otp[5]" [ngModelOptions]="{standalone: true}"
                            (input)="onInputChange(5)" [class.invalid]="submitted && !otp[5]" #otp6 />
                    </div>
                    <small *ngIf="submitted1" class="alert px-0 pb-0 m-0">
                        Invaild OTP
                    </small>
                    <small *ngIf="checkVal" class="alert px-0 pb-0 m-0">
                        OTP is required
                    </small>
                    <div class="d-flex justify-content-center mt-4 mb-4 mb-md-0 resendcode">
                        <div class="d-flex text-center justify-content-center">
                            <p>Didn’t receive one?</p>
                            <button type="button" (click)="onOtpSend(loginForm.value,type)" class="ms-1 cursor"
                                [ngClass]="{'disable-resend': resenddata}">Resend in</button>
                        </div>
                        <div>
                            <span class="counternumber pe-5 me-2 text-center">
                                {{data3}}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="postdata ">
                <button type="submit" [ngClass]="{'disable-verify': !verifybtn}" [disabled]="!verifybtn">Verify</button>
            </div>
        </form>


        <form (submit)="onResetpass(passwordForm.value)" class="text-center" *ngIf="changepassword" [formGroup]="passwordForm">
            <h4 class="mt-3">Reset Password</h4>
            <div class="phonedetail d-grid mt-2 justify-content-center">
                <div class="w-90 position-relative mt-2">
                    <div class="cursor-pointer" (click)="onClick()">
                        <img class="securepassword" *ngIf="!show" src="assets/images/new_img/unlock.svg" alt=""
                            width="20px" height="20px">
                        <img class="securepassword smsdata1" *ngIf="show" src="assets/images/new_img/unlock.svg" alt=""
                            width="20px" height="20px">
                    </div>
                    <input [type]="password" class="form-control emaildata ps-3" maxlength="16"
                        (keydown)="validators.avoidSpace($event)" placeholder="Enter New Password"
                        formControlName="new_password">
                    <small *ngIf=" submitted && passwordForm.controls['new_password'].invalid " class="alert">
                        <small *ngIf="submitted && passwordForm.controls['new_password'].errors.required">
                            Password is required
                        </small>
                    </small>
                </div>
            </div>

            <div class="phonedetail d-grid mt-2 justify-content-center">
                <div class="w-90 position-relative mt-2">
                    <div class="cursor-pointer" (click)="confirm()">
                        <img class="securepassword" *ngIf="!confirmshow" src="assets/images/new_img/unlock.svg" alt=""
                            width="20px" height="20px">
                        <img class="securepassword smsdata1" *ngIf="confirmshow" src="assets/images/new_img/unlock.svg"
                            alt="" width="20px" height="20px">
                    </div>
                    <input [type]="confirmpassword" class="form-control emaildata ps-3" maxlength="16"
                        (keydown)="validators.avoidSpace($event)" placeholder="Confirm Password"
                        formControlName="confirm_password">
                    <small *ngIf=" submitted && passwordForm.controls['confirm_password'].invalid " class="alert">
                        <small *ngIf="submitted && passwordForm.controls['confirm_password'].errors.required">
                            Password is required
                        </small>
                    </small>
                    <small
                        *ngIf="submitted && passwordForm.controls['confirm_password']?.errors?.passwordsNotMatch">Confirm
                        Password
                        does not match</small>
                </div>
            </div>

            <div class="postdata ">
                <button type="submit">Verify</button>
            </div>
        </form>
    </div>
</div>