import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteConstant } from 'src/app/core/_constants/route.constant';

@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.scss']
})
export class AddNewComponent implements OnInit {
  open: any;
  RouteConstant:any = RouteConstant;
  addPostNavigation: any = '/'  +RouteConstant.main + '/' + RouteConstant.home + '/' + RouteConstant.createPost;
  selectRequirement:any = '/'  +RouteConstant.main + '/' + RouteConstant.home + '/' + RouteConstant.selectRequirement
  constructor( public dialogRef: MatDialogRef<AddNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
