<div class="row">
    <div class=" col-md-12 d-block pb-0 text-end">
        <button class="button-cls" (click)="closeDialog()">
            <img src="assets/images/arrow_close.svg">
        </button>
    </div>
</div>
<div class="container p-0 mainnn">
    <div class="row m-0 d-flex justify-content-center bg-color">
        <!--  <div class="col-lg-6 p-0  sideimg">
            <div class="signupback">
                <img src="assets/images/auth.png">
            </div>
        </div> -->
        <div class="col-lg-12 px-md-2 px-3 reset loginpagenew">
            <!-- <img class="mt-3 logo" src="assets/images/raftaarrlogo.png" alt="logo"> -->
            <h3 class="mt-4 mb-0  loginaccount">Log In to Your Account</h3>
            <!-- <p class="formabout" *ngIf="mobilelogin">
                Kindly login to the Raftaarr using your registered phone number.
            </p> -->
            <!-- <p class="formabout" *ngIf="emaillogin">
                Kindly login to the Raftaarr using your registered email address.
            </p> -->
            <!-- login with phone -->
            <form *ngIf="mobilelogin" [formGroup]="loginForm" (submit)="onOtpSend(loginForm.value)">
                <div class="phonedetail d-grid ">
                    <div class="w-90 position-relative  mt-4">
                        <p class="position-absolute c_input border-end pe-2 me-2">+91 </p>
                        <!-- <p class="counternumber px-2 text-center"><span
                                *ngIf="loginForm.controls.phone_no.value.length<=9 && loginForm.controls.phone_no.value.length>0">0</span>{{loginForm.controls.phone_no.value.length}}/10
                        </p> -->
                        <input type="text" class="form-control countrycode" formControlName="phone_no" maxlength="10"
                            placeholder="Enter Mobile Number" appDigitOnly>
                        <!-- <small *ngIf="(submitted && loginForm.get('phone_no').invalid)">Phone Number is required
                        </small>  -->


                        <small *ngIf="submitted && loginForm.controls.phone_no.invalid" class="alert py-0 mb-0">
                            <small *ngIf="loginForm.controls['phone_no'].errors.required">
                                Phone number is required.
                            </small>
                            <small *ngIf="loginForm.controls['phone_no'].errors.pattern">
                                Phone number must contains valid numbers.
                            </small>
                        </small>
                    </div>
                </div>
                <div class="social-login text-center mt-4">
                    <div class="w-90 divider mb-4">
                        <span>or Login with</span>
                    </div>
                    <button type="button" class=" py-1"><img src="assets/images/new_img/facebook.svg"
                            (click)="facebookSignin()" alt=""></button>
                    <button type="button" class="mx-2  py-1 cursor-pointer" (click)="googleSignin()"><img
                            src="assets/images/new_img/google.svg" alt=""></button>
                    <button type="button" class=" py-1"><img src="assets/images/new_img/linkedin.svg"
                            (click)="linkedIn()" alt=""></button>
                </div>

                <div class="text-center mt-4" [title]="'Log In with Email'">
                    <button type="button" class="align-middle
                     gmailloginbtn cursor-pointer" (click)="loginwithmobile()">

                        <!-- <img src="assets/images/sms@1x.png" alt="" class="d-inline-block align-middle mb-1"> -->
                        Log In with Email
                    </button>
                </div>
                <div class="text-center mt-3" [title]="'Get OTP'">
                    <button type="submit" class="loginbtn" *ngIf="mobilelogin">Get OTP</button>
                </div>
                <div class="text-center mt-3">
                    <!-- <button type="button" title="login with gmail" class="align-middle
                     gmailloginbtn cursor-pointer"  (click)="guestLogin()">
                        Continue as Guest

                    </button> -->
                    <!-- <button type="button" class="loginbtn" (click)="guestLogin()">Continue as Guest</button> -->
                </div>

            </form>

            <!-- login with Email -->

            <form class="mt-3" *ngIf="emaillogin" [formGroup]="emailloginForm"
                (submit)="onSubmit(emailloginForm.value)">

                <div class="phonedetail d-grid ">

                    <div class="w-90 position-relative mt-2">
                        <!-- <img class="message" src="assets/images/sms-edit.svg" alt=""> -->
                        <input type="email" class="form-control emaildata ps-3" formControlName="username"
                            maxlength="50" (keydown)="validators.avoidSpace($event)" placeholder="Enter Email">
                        <small *ngIf=" submitted && emailloginForm.controls['username'].invalid" class="alert">
                            <small *ngIf="submitted && emailloginForm.controls['username'].errors.required">
                                Email is required
                            </small>
                            <small *ngIf="emailloginForm.get('username').errors.pattern">
                                Please enter valid email address
                            </small>
                        </small>

                    </div>
                </div>
                <div class="phonedetail d-grid mt-2">

                    <div class="w-90 position-relative mt-2">
                        <div class="cursor-pointer" (click)="onClick()">
                            <img class="securepassword" *ngIf="!show" src="assets/images/new_img/unlock.svg" alt=""
                                width="20px" height="20px">
                            <img class="securepassword smsdata1" *ngIf="show" src="assets/images/new_img/unlock.svg"
                                alt="" width="20px" height="20px">
                        </div>
                        <!-- <img class="securepassword" src="assets/images/lock@1x.png" alt="" (click)="hidePassword = !hidePassword"> -->
                        <input [type]="password" class="form-control emaildata ps-3" maxlength="16"
                            (keydown)="validators.avoidSpace($event)" placeholder="Enter Password"
                            formControlName="password">
                        <small *ngIf=" submitted && emailloginForm.controls['password'].invalid " class="alert">
                            <small *ngIf="submitted && emailloginForm.controls['password'].errors.required">
                                Password is required
                            </small>
                        </small>
                    </div>
                </div>
                <div class="w-90 forgot mt-2 d-flex justify-content-end cursor" [title]="'Forgot Password?'"
                    (click)="onClickRoute()">
                    <p> Forgot Password? </p>
                </div>
                <div class="social-login text-center">
                    <div class="w-90 divider mb-4">
                        <span>or Login with</span>
                    </div>
                    <button type="button" class=" py-1"><img src="assets/images/new_img/facebook.svg"
                            (click)="facebookSignin()" alt=""></button>
                    <button type="button" class="mx-2  py-1 cursor-pointer" (click)="googleSignin()"><img
                            src="assets/images/new_img/google.svg" alt=""></button>
                    <button type="button" class=" py-1"><img src="assets/images/new_img/linkedin.svg"
                            (click)="linkedIn()" alt=""></button>
                </div>
                <div class="clearfix"></div>
                <div class="text-center mt-4" [title]="'Login with phone'">
                    <button type="button" class="align-middle
              gmailloginbtn cursor" (click)="loginwithemail()">
                        <!-- <img src="assets/images/mobile@1x.png" alt="" class="d-inline-block align-middle"> -->
                        Login with phone
                    </button>
                </div>
                <div class="text-center mt-3">
                    <button type="submit" class="loginbtn" *ngIf="emaillogin">Continue</button>
                </div>
            </form>
            <!-- <div class="text-center mt-3" *ngIf="emaillogin">
                <button class="loginbtn" (click)="onSubmit()">Continue</button>
            </div> -->
            <div class="text-center mt-3">
                <p class="noaccount">Don't have an account?
                    <button type="button" class="createaccount" (click)="onClickRouteSignUp()"
                        [title]="'Create an account'">Create an
                        account</button>
                </p>
            </div>
            <div class="w-90 divider">
                <span>or</span>
            </div>
            <div class="text-center mt-4 " title="login with sso">
                <button type="button" class="align-middle
                      cursor-pointer  ssologinbtn">
                    <span class="loginsso"> LogIn with SSO</span>
                </button>
            </div>
        </div>
    </div>
</div>