import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationsService {
  regex:any = {
    emailPattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,10}$",
    mobilePattern: /^[1-9]\d{9}$/,
    passwordPattern: /^[A-Za-z0-9_@.!$%^*()=/#&+-]{8,}$/,
    gstPattern: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
    hsnPattern: "[0-9]{6,8}",
    digitPattern: "[0-9]+",
    numberPattern: "[\\d+(?:\\.\\d+)]+",
    namePattern: "[a-zA-Z ]+",
    taxPattern: /^\d+(\.\d+)?$/,
    pricePattern: /^[1-9]{1,9}$/,
    discountPattern: /^[ a-zA-Z ]+$/,
    amountReceivedPattern: /^[0-9]{1,9}$/,
    finalPaymentPattern: /^[0-9]{1,10}$/,
    orderNumPattern: "^[a-zA-Z0-9]{10}$",
    companyNamePattern: "[a-zA-Z ]{1,50}",
    receiptPattern: /^[a-zA-Z0-9]{1,20}$/,
    panPattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
    milestoneNamePattern: /^[ a-zA-Z0-9]+$/,
    mobPattern: "[0-9]{10}",
  }

  constructor() { }

  avoidSpace(event1: any) {
    var k = event1 ? event1.which : event1.keyCode
    if (k == 32 && (event1.target.value.length >= 0)) {
      return false
    } 
      return true
  }
  
   numeric(event:any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode>31&&(charCode<48||charCode>57)){
         
        return false;
    }
return true;
    }

    alphaNumericKey(event:any)
    {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)&& (charCode < 48 || charCode > 57)) {
    return false;}
    return true;
    }  
    onlynumbers(event:any){
      const charCode=(event.which)?event.which:event.keyCode;
      if(charCode>31&&(charCode<48||charCode>57)){
           
          return false;
      }
  return true;
  }


  //only alphabets

  lettersOnly(event1: any) {
    var charCode = event1.keyCode;

    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8)

      return true;
    else
      return false;
  }

  isNumberKey(evt:any){  
     
    //var e = evt || window.event;
	var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode != 46 && charCode > 31 
	&& (charCode < 48 || charCode > 57))
        return false;
        return true;
	}

}


     
