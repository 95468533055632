<div class="modal-header pt-0 pb-0">
    <img src="assets/images/close.png" alt="" class="cursor" [mat-dialog-close]="false">
</div>
<div class="modal-body pt-0 text-start">
    <form [formGroup]="myForm">
        <label>Delay by days</label>
        <input type="text" formControlName="is_delay" class="w-100" placeholder="Enter delay by days"
        appDigitOnly />
        <div *ngIf="submitted && myForm.controls['is_delay'].errors">
            <small  *ngIf="submitted && myForm.controls['is_delay'].errors.required">Delay by days is required</small>
        </div>
        <label>Select milestone</label>
        <div class="d-flex justify-content-between flex-column">
            <mat-form-field appearance="fill">
                <mat-select placeholder="Select milestone" formControlName="id">
                    <mat-option *ngFor="let item of milestoneListData" [value]="item.id">{{item.milestone_name}} {{' - '}}{{item.created_at | date:'dd/MM/yyyy HH:mm'}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="submitted && myForm.controls['id'].errors">
                <small *ngIf="submitted && myForm.controls['id'].errors.required">Please select milestone</small>
            </div>
        </div>
    </form>

</div>
<div class="modal-footer d-flex justify-content-between pt-4">
    <button type="button" class="btn report" (click)="onSubmit()">Delay</button>
</div>