import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { SignInService } from 'src/app/services/sign-in.service';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
import { EditEnquiryComponent } from 'src/app/modules/pages/profile/edit-enquiry/edit-enquiry.component';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from "src/app/services/common.service"; 
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  open: any;
  profileDetails: any;

  constructor(private router: Router, private dialog: MatDialog, private storageService: LocalStorageProvider,
    private service: ApiServicesService, public dialogRef: MatDialogRef<LogoutComponent>, private afAuth: AngularFireAuth,
    private signInService: SignInService, private toastr: ToastrService,    private commonService: CommonService,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log(data.people.result, 'data');

  }

  
  ngOnInit(): void {
    // this.getProfile();
    // this.profileDetails = this.data.data
  }
  logout() {
    this.logoutAPI();
  }

  selectedData: { productId: string | null } = { productId: null }; 

  toggleSelection(id: string): void {
    this.selectedData.productId = this.selectedData.productId === id ? null : id;
  }

  getSelectedQuotes(): void {
     if (!this.selectedData) {
      this.toastr.error("Product ID is missing.");
      return;  
    }
    const url = API_ROUTES.MyRequirements.addToGetQuote;
    this.service.post( this.selectedData , url, {})
      .pipe()
      .subscribe({
        next: (res) => {
          if (res.success) { 
            this.toastr.success(res.message);
           this.closeDialog();
          }
        },
        error: (error) => {
          this.toastr.error(this.commonService.returnErrorMessage(error));
        }
      });
  }
 

  getProfile() {
    this.service.get({}, `${API_ROUTES.More.moreDetails}`).pipe().subscribe((res) => {
      this.profileDetails = res.result[0];
    })
  }

  async signOut() {
    await this.afAuth.signOut();
    this.logoutAPI();
  }


  logoutAPI() {
    const params = {
      device_id: localStorage.getItem('device_Id')?.toString()
      // device_id: sessionStorage.getItem('device_Id')?.toString()
    };

    // console.log("Initiating logout with params:", params);

    this.service.delete(params, `${API_ROUTES.Account.logout}`).subscribe(
      (res) => {
        // console.log("Logout API response:", res);

        if (res.success) {
          // console.log("Logout successful, clearing session data...");
          this.clearSessionData();
        } else {
          console.log("Logout failed. Response did not indicate success.");
        }
      },
      (error) => {
        console.error("Logout error:", error);
        // console.log("Clearing session data due to error.");
        this.clearSessionData();
      }
    );
  }
  closeDialog(): void {
    this.dialog.closeAll(); // Pass data when closing
  }
  // Separate method to handle clearing session data and reloading
  private clearSessionData() {

    localStorage.removeItem('stoken');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expToken');
    localStorage.setItem('deviceToken', 'EMPTY');
    localStorage.removeItem('userToken');
    localStorage.removeItem('forgotExpToken');

    this.generateAndStoreDeviceToken();
    this.router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }
  generateAndStoreDeviceToken() {
    // Declare the function to generate a custom token
    const generateCustomToken = function (length: number) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
      let token = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters[randomIndex];
      }
      return token;
    }

    // Check if deviceToken already exists in localStorage
    let deviceToken = localStorage.getItem('deviceToken');
    if (!deviceToken || deviceToken.length < 180) {
      // If no deviceToken exists or it is invalid, generate a new one
      deviceToken = generateCustomToken(180);
      localStorage.setItem('deviceToken', deviceToken);
    } else {
      console.log('Using existing device token:', deviceToken);
    }
  }
  enquiry() {
    let token = localStorage.getItem('stoken');
    if (token) {
      this.dialogRef.close();
      this.dialog.open(EditEnquiryComponent, {
        maxHeight: '100vh',
        width: '1000px',
      });
    }
    else {
      this.toastr.error('Need to login first');
      this.dialogRef.close();
      this.dialog.open(LoginComponent, {
        maxHeight: '100vh',
        width: '900px',
      })
    }
  }
}
