<div class="row" *ngIf="add">
    <div class="col-md-3">
        <img class="img1" src="assets/images/new_img/raftar-logo.svg" alt="">
    </div>
    <div class=" col-md-9 modal-header pb-0 text-end">
        <button class="button-close" (click)="closeDialog()">
            <img src="assets/images/arrow_close.svg">
        </button>
    </div>
</div>
<div class="main">
    <div *ngIf="!myProfile" class="d-flex mb-3 back align-items-center">
        <img class="cursor gb" src="assets/images/arrow-circle-left.svg">
        <h3 class="ms-2 mb-0 cursor">Back</h3>
    </div>
    <div Class="row m-0 ">
        <div class="hideonmodal col-lg-2 p-0 profile mt-0 position-relative" *ngIf="update">
            <app-profile-front-page></app-profile-front-page>
        </div>
        <div class="bg-white py-3 banner " [ngClass]="update ? 'col-md-10' : 'col-md-12'" style="margin: 0 auto;">
            <div class="row headerbox">
                <div class="col-md-12 justify-content-center d-flex align-items-end" *ngIf="add">
                    <h3 class="head3 mb-4">Post Your Enquiry </h3>
                </div>
                <div class="col-md-12" *ngIf="update">
                    <h3 class="head3 mb-4">Enquiries</h3>
                </div>
            </div>
            <div class="p-0 mainnn">
                <div class="row m-0 d-flex  justify-content-center bg-color">
                    <div class="col-lg-12 reset ">
                        <form [formGroup]="loginForm" (submit)="onSubmit(loginForm.value)">
                            <div class="phonedetail d-grid ">
                                <div class="customwidth position-relative mt-2">
                                    <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                        placeholder="Company Name" formControlName="company_name">
                                </div>
                                <small *ngIf="submitted &&  loginForm.controls['company_name']?.errors?.required"
                                    class="error-message">
                                    Company Name is required.
                                </small>
                                <div class="customwidth position-relative  mt-2">
                                    <p class="position-absolute c_input border-end pe-2 me-2">+91 </p>
                                    <input type="text" class="form-control countrycode" formControlName="phone_no"
                                        maxlength="10" placeholder="Enter Phone Number" appDigitOnly>
                                </div>
                                <small *ngIf="submitted && loginForm.controls['phone_no']?.errors?.required"
                                    class="error-message">
                                    Phone Number is required.
                                </small>
                                <div class="customwidth position-relative mt-2">
                                    <mat-form-field appearance="fill" class="mat-select-display">
                                        <mat-select placeholder="Select Offerings" #select2
                                            (selectionChange)="onIndustryChange($event.value)"
                                            (openedChange)="onSelectOpenClose($event, 'category')"
                                            formControlName="industry_id">
                                            <mat-option *ngFor="let category1 of category" [value]="category1.id">
                                                {{category1.industry_name}}
                                            </mat-option>
                                            <mat-option *ngIf="category?.length == 0" disabled>No items
                                                available</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <small *ngIf="submitted &&loginForm.controls['industry_id']?.errors?.required"
                                        class="error-message">
                                        Offering is required.
                                    </small>
                                </div>
                                <!-- loginForm.get('company_name')?.invalid && (loginForm.get('company_name')?.touched || loginForm.get('company_name')?.dirty)||
                                <div class="customwidth position-relative mt-2">
                                    <mat-form-field appearance="fill" class="mat-select-display">
                                        <mat-select
                                            [placeholder]="machine == false ?'Select Services':'Select Industry'"
                                            #select1 (openedChange)="onSelectOpenClose($event, 'industry')"
                                            formControlName="category_id">
                                            <mat-option *ngFor="let industry1 of industry" [value]="industry1.id">
                                                {{industry1.sub_category_name}}
                                            </mat-option>
                                            <mat-option *ngIf="industry?.length == 0" disabled>No items
                                                available</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="submitted && loginForm.controls['category_id'].hasError('required')">Category
                                            is
                                            required.</mat-error>
                                    </mat-form-field>
                                </div> -->
                                <!-- <label class="custom-label">
                                    Category [placeholder]="machine == false ?'Select Services':'Select Industry'"]
                                </label> -->
                                <!-- {{machine | json}} -->
                                <div class="customwidth position-relative mt-2">
                                    <mat-form-field appearance="fill" class="mat-select-display width100 mb-2">
                                        <mat-select formControlName="category1_id" #select2 panelClass="s1"
                                            [placeholder]="services == true ?'Select Services':'Select Industry'"
                                            (selectionChange)="onIndustryBycat2($event.value)">
                                            <mat-option *ngFor="let category1 of category1" [value]="category1.id">
                                                {{ category1.title }}
                                            </mat-option>
                                            <mat-option *ngIf="category1?.length === 0" disabled>No items
                                                available</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <small *ngIf="submitted && loginForm.controls['category1_id']?.errors?.required"
                                        class="error-message">
                                        Industry is required.
                                    </small>
                                </div>
                                <!-- <small *ngIf="submitted && addForm.controls['category1_id'].hasError('required')">
                                    Industry is required
                                </small> -->

                                <!-- <label class="custom-label">
                                    Category <span class="required-asterisk">*</span>
                                </label> -->
                                <div class="customwidth position-relative mt-2">
                                    <mat-form-field appearance="fill" class="mat-select-display width100 mb-2"
                                        #formField>
                                        <mat-select placeholder="Category" formControlName="category2_id" #select2
                                            panelClass="s1" (selectionChange)="oncatBycat2($event.value)">
                                            <mat-option *ngFor="let category1 of category2" [value]="category1.id">
                                                {{category1.title}}
                                            </mat-option>
                                            <mat-option *ngIf="category2?.length === 0" disabled>No items
                                                available</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <small *ngIf="submitted &&  loginForm.controls['category2_id']?.errors?.required"
                                        class="error-message">
                                        Category is required.
                                    </small>
                                </div>
                                <div class="customwidth position-relative mt-2" *ngIf="services == true">
                                    <mat-form-field appearance="fill" class="mat-select-display width100 mb-2"
                                        #formField>
                                        <mat-select placeholder="Sub Category" formControlName="category3_id" #select2
                                            panelClass="s1">
                                            <mat-option *ngFor="let category1 of category3" [value]="category1.id">
                                                {{category1.title}}
                                            </mat-option>
                                            <mat-option *ngIf="category3?.length === 0" disabled>No items
                                                available</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <small *ngIf="submitted &&  loginForm.controls['category2_id']?.errors?.required"
                                        class="error-message">
                                        Category is required.
                                    </small> -->
                                </div>
                                <!-- <div *ngIf="!machine">
                                    <div class="customwidth position-relative mt-2">
                                        <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Service Name" formControlName="service_name">
                                    </div>
                                </div>
                                <div *ngIf="machine">
                                    <div class="customwidth position-relative mt-2">
                                        <input type="text" class="form-control emaildata ps-3" maxlength="50"
                                            placeholder="Product Name" formControlName="product_name">
                                    </div>
                                </div> -->
                                <!-- <div class="locateyourdata mt-2 align-items-center">
                                    <input type="text" appAlphaNumOnly maxlength="30"
                                        class="form-control emaildata ps-3" formControlName="location"
                                        placeholder="Location">
                                    <img src="assets/images/location_2.svg" class="calendar" alt="location">
                                </div>
                                <small
                                    *ngIf="submitted && loginForm.controls['location']?.errors?.required"
                                    class="error-message">
                                    Location is required.
                                </small> -->
                                <div class="customwidth position-relative mt-2">
                                    <div class="row mt-2">
                                        <div class="col-md-10">
                                            <p><span class="loan ms-3"><b>Required Loan</b></span></p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="toggle-switch">
                                                <input type="checkbox" id="toggle"
                                                    [checked]="loginForm.get('loan_required')?.value === true "
                                                    (change)="onToggleChange($event, 'loan_required')" />
                                                <label for="toggle" class="slider"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="customwidth position-relative mt-2">
                                    <textarea type="text" class="form-control emaildata ps-3" maxlength="1500"
                                        placeholder="Brief Description (max 1500 words)"
                                        formControlName="description"></textarea>
                                </div>
                                <small *ngIf="submitted &&  loginForm.controls['description']?.errors?.required"
                                    class="error-message">
                                    Description is required.
                                </small>
                                <div class="customwidth mt-3 d-flex custom-checkbox">
                                    <label>
                                        <input name="termsAccepted" type="checkbox" formControlName="termsAccepted"
                                            id="termsCheckbox" />
                                        <span class="checkmark"></span>
                                        <p class="ms-2 noaccount mt-3">
                                            I accept the
                                            <button type="button" class="createaccount ps-0 ms-0" [title]="'Terms & Conditions'" (click)="navigateToTermsOfUse()">
                                                Terms & Conditions
                                            </button>
                                        </p>
                                    </label>

                                    <!-- <label for="termsCheckbox" class="d-flex align-items-center">
                                        <input type="checkbox" formControlName="termsAccepted" id="termsCheckbox">
                                        <p class="ms-2 noaccount mt-3">
                                            I accept the
                                            <button type="button" class="createaccount" [title]="'Terms & Conditions'">
                                                Terms & Conditions
                                            </button>
                                        </p>
                                    </label> -->
                                </div>

                                <!-- <div class="customwidth mt-3 d-flex">
                                    <input type="checkbox" formControlName="termsAccepted" id="termsCheckbox">
                                    <p class="ms-2 noaccount mt-3"> I accept the
                                        <button type="button" class="createaccount" [title]="'Terms & Conditions'">Terms
                                            &
                                            Conditions </button>
                                    </p>
                                </div> -->
                            </div>
                            <div class="customwidth position-relative mt-3">
                                <button type="submit" *ngIf="add" class="loginbtn custom-op"  [ngClass]="{'enabled-btn': loginForm.get('termsAccepted').value}"[disabled]="!loginForm.get('termsAccepted').value">Post
                                    Enquiry</button>
                                <button type="submit" *ngIf="update" class="loginbtn">Update
                                    Enquiry</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>