import { Component, ElementRef, OnInit, ViewChild, Inject, AfterViewInit, ViewChildren, QueryList } from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { API_ROUTES } from "src/app/core/_constants/api-route.constant";
import { ApiServicesService } from "src/app/services/apiServices/api-services.service";
import { LocalStorageProvider } from "src/app/services/storage/storage.service";
import { ValidationsService } from "src/app/services/validators/validator";
import { DeviceUUID } from "device-uuid";
import { DeviceDetectorService } from "ngx-device-detector";
import { DataService } from "src/app/services/data.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { I, T } from "@angular/cdk/keycodes";
import { CommonService } from "src/app/services/common.service";
import { RouteConstant } from "src/app/core/_constants/route.constant";
import { AuthService } from "src/app/services/authguard/auth.service";
import { Location } from "@angular/common";
import { CryptoProvider } from './../../services/storage/crypto.service'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginComponent } from "../login/login.component";
interface DataInterface {
  company_name: boolean;
  description: boolean;
  address: boolean;
  industry_id: boolean;
  category1_id: boolean;
  categoryl5_id: boolean;
  gst_no: boolean;
  company_size: boolean;
  email: boolean;
  phone_no: boolean;
  total_machine: boolean;
  incorporated_year: boolean;
  state: boolean;
  city: boolean;
  lastyear_turnover: boolean;
  constitution_of_business: boolean;
}

@Component({
  selector: "app-phone-otp-varification",
  templateUrl: "./phone-otp-varification.component.html",
  styleUrls: ["./phone-otp-varification.component.scss"],
})
export class PhoneOtpVarificationComponent implements AfterViewInit {
  @ViewChild("otp") ngOtpInputRef: any;
  // ViewChild to reference input fields
  @ViewChildren('otp1, otp2, otp3, otp4, otp5, otp6') otpInputs: QueryList<ElementRef> | undefined;

  otpForm: FormGroup;
  loginForm: any;
  passwordForm: any;
  data1: any;
  data3: any;
  timeLeft: number = 11;
  interval: any;
  resenddata: boolean = false;
  phone_no: any;
  phone: any;
  type: number = 1;
  loginExpToken: any;
  loginOTpverify: any;
  loginContact: any;
  forgotExpToken: any;
  forgotContact: any;
  phoneNumber: any;
  uuid = new DeviceUUID().get();
  deviceInfo = this.deviceService.getDeviceInfo();
  currentDate = new Date();
  // otp: any;
  inputOtp: any;
  timerOn: boolean = true;
  submitted: boolean = false;
  submitted1: boolean = false;
  checkVal: boolean = false;
  counter: number = 0;
  config = {
    length: 6,             // Length of the OTP
    isInputSecure: true,   // Mask OTP input
    autoFocus: true,       // Automatically focus on the first input
    allowNumbersOnly: true // Only allow numeric input
  };
  otp: string[] = ['', '', '', '', '', '']; // Array to hold OTP digits
  // isOtpValid: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public validators: ValidationsService,
    private service: ApiServicesService,
    private router: Router,
    private commonService: CommonService,
    private storageService: LocalStorageProvider,
    private activeRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private dataService: DataService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private authService: AuthService,
    private location: Location,
    private crypto: CryptoProvider,
    private dialogRef: MatDialogRef<PhoneOtpVarificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {
  }
  ngAfterViewInit(): void {
    if (this.otpInputs) {
      this.otpInputs.toArray()[0].nativeElement.focus(); // Focus on the first input field
    }
  }
  onInputChange(index: number): void {
    if (this.otp[index] && index < 5) {
      // Focus the next input field
      const nextInput = this.otpInputs?.toArray()[index + 1];
      if (nextInput) {
        nextInput.nativeElement.focus();
      }
    }
  }
  closeDialog(): void {
    this.dialog.closeAll(); // Pass data when closing
  }
  onResetpass() {
    this.submitted = true;
    if (this.passwordForm.valid) {
      this.service.post(this.passwordForm.value, `${API_ROUTES.Account.resetPassword}`, {}).pipe().subscribe((res) => {
        console.log(res);
        if (res.success) {
          this.toastr.success(res.message);
          this.dialogRef.close();
          const logindialogRef = this.dialog.open(LoginComponent, {
            maxHeight: '100vh',
            width: '900px',
          })
        }
      },
        (error) => {
          this.toastr.error(error.message);
          console.log(error);
        })
    }
  }
  loginPost: any = [];
  changepassword: boolean = false;
  onSubmit(): void {
    this.submitted = true;
    if (this.otp.length === 6) {
      const otpValue = this.otp.join('');
      this.loginExpToken = localStorage.getItem("expToken");
      const expireToken = localStorage.getItem("refresh_token")
      if (this.data.type == '3') {
        this.changepassword = true;
        const requestData = {
          country_code: '+91',
          phone_no: this.data.phoneNumber,
          otp: otpValue,
        };
        this.service
          .post(requestData, `${API_ROUTES.Account.forgotPasword}`, {
            expToken: expireToken || this.loginExpToken,
          })
          .subscribe(
            (res) => {
              console.log(res, 'res');
              if (res.success_code == 201) {
                localStorage.setItem('userToken', res.result.userToken);
              }
            },
            (error) => {
              this.submitted1 = true;
              this.checkVal = false;
              if (
                this.commonService
                  .returnErrorMessage(error)
                  .includes("Please try again after 15 mins")
              ) {
                this.resenddata = true;
                this.toastr.error(this.commonService.returnErrorMessage(error));
              }
            }
          );
      } else {
        const requestData = {
          country_code: '+91',
          phone_no: this.data.phoneNumber,
          otp: otpValue,
          type: 'login',
          device_token: localStorage.getItem("deviceToken"),
          device_id: localStorage.getItem("device_Id"),
          device_type: this.deviceInfo.deviceType
        };
        this.service
          .post(requestData, `${API_ROUTES.Account.loginOtp}`, {
            expToken: this.loginExpToken || expireToken,
          })
          .subscribe(
            (res) => {
              console.log(res, 'rerere');
              if (res.success_code == 201) {

                localStorage.removeItem("guestUser");
                localStorage.removeItem("currentTime");
                localStorage.setItem('stoken', res.result.token);
                localStorage.setItem("refresh_token", res.result.refresh_token)
                localStorage.setItem("UserId", res.result.id);
                localStorage.setItem(
                  "companyInterest",
                  res.result.isCompanyInterest
                );
                window.location.reload();
                // this.router.navigate(['/']);
              }
            },
            (error) => {
              this.submitted1 = true;
              this.checkVal = false;
              if (
                this.commonService
                  .returnErrorMessage(error)
                  .includes("Please try again after 15 mins")
              ) {
                this.resenddata = true;
                this.toastr.error(this.commonService.returnErrorMessage(error));
              }
            }
          );
      }
    } else {
      this.submitted1 = true;
      this.toastr.error('Invalid OTP');
      console.log('Invalid OTP');
    }
  }

  companyInfo: any = {};
  ngOnInit(): void {
    this.forgotContact = this.data.phoneNumber;
    this.phone_no = this.data.phoneNumber;
    this.phone = this.phone_no?.phone_no;
    this.phone = Number(this.phone);
    this.type = Number(this.data.type);
    this.loginForm = this.fb.group({
      country_code: ["+91"],
      phone_no: [this.data.phoneNumber || this.phone_no],
    });

    this.otpForm = this.fb.group({
      country_code: ["+91"],
      phone_no: [this.loginContact],
      otp: ["", [Validators.required]],
      type: "verification",
    });

    this.passwordForm = this.fb.group({
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
      user_token: localStorage.getItem('userToken')?.toString(),
    }
    );

    if (!this.loginContact && this.type == 4) {
      this.router.navigateByUrl('/main/more/edit-profile');
      return
    }

    if (this.data.type == 1) {
      // inn case of singup // done
      this.loginContact = this.dataService.getDataByKey("loginPhone") || this.storageService.getItem('phoneNumber')
      this.otpForm.patchValue({
        phone_no: this.loginContact
      })
      this.onOtpSend(this.loginForm.value, 1);
    } else if (this.data.type == 2) {
      // in case of login screen // done
      this.loginContact = this.dataService.getDataByKey("loginPhone") || this.data.phoneNumber
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.otpForm = this.fb.group({
        country_code: ["+91"],
        phone_no: [this.data.phoneNumber],
      });
      this.onOtpSend(this.otpForm.value, 2);
    } else if (this.type == 3) {
      this.loginContact = this.data.phoneNumber || this.storageService.getItem('phoneNumber')
      this.otpForm = this.fb.group({
        country_code: ["+91"],
        phone_no: [Number(this.loginContact || this.forgotContact)],
      });
      this.onOtpSend(this.otpForm.value, 3);
    }
  }
  expTkn: any;
  onOtpSend(post: any, type: any) {
    if (this.ngOtpInputRef) this.ngOtpInputRef.setValue("");
    this.submitted = true;
    if (this.storageService.getItem("currentTime") && type == 2) {
      this.otpCount(post);
    } else if (type == 1) {
      this.service
        .post(post, `${API_ROUTES.Account.otpSendPhoneSignUp}`, {})
        .pipe()
        .subscribe(
          (res) => {
            this.data1 = res.result.expToken;
            if (res.success_code == 201) {
              this.otpCount(post);
              if (type == 2) {
                this.otpCount(post);
              }
            }
          },
          (error) => {
            if (
              this.commonService
                .returnErrorMessage(error)
                .includes("Please try again after 15 mins")
            ) {
              this.toastr.error(this.commonService.returnErrorMessage(error));
              this.resenddata = true;
              this.submitted1 = false;
            }
          }
        );
    } else {
      if (!post.phone_no) {
        post.phone_no = this.data.phoneNumber;
      }
      this.service
        .post(post, `${API_ROUTES.Account.otpSendPhone}`, {})
        .pipe()
        .subscribe(
          (res) => {
            if (res.success_code == 201) {
              this.expTkn = res.result.expToken;
              localStorage.setItem("expToken", this.expTkn);
              localStorage.setItem("refresh_token", this.expTkn);
              localStorage.setItem('window', window.location.href)
              // console.log('expTkn', this.expTkn);
              if (this.data.type == 2) {
                this.otpCount(post);
              }
              if (this.data.type == 3) {
                this.otpCount(post);
              }
            }
          },
          (error) => {
            if (
              this.commonService
                .returnErrorMessage(error)
                .includes("Please try again after 15 mins")
            ) {
              this.toastr.error(this.commonService.returnErrorMessage(error));
              this.resenddata = true;
            }
          }
        );
    }
  }
  unsuccessfulAttempts = 0;
  otpCount(data: any) {
    if (this.storageService.getItem("currentTime")) {
      let currentTime: any = new Date().getTime();
      let pastTime: any = this.storageService.getItem("currentTime");
      let diff = currentTime - pastTime;
      this.loginOtpCounter(diff, data);
    } else {
      if (this.unsuccessfulAttempts >= 5) {

        this.disableResendButtonFor15Minutes();
      } else {
        if (this.unsuccessfulAttempts === 4) {
          this.unsuccessfulAttempts += 1;
          let date: any = new Date().getTime();
          this.storageService.setItem("currentTime", date);
        } else {
          this.unsuccessfulAttempts += 1;
        }
        this.timer(60);
      }
    }
  }
  disableResendButtonFor15Minutes() {
    this.resenddata = false;
    setTimeout(() => {
      this.resenddata = true;
    }, 15 * 60 * 1000);
  }

  loginOtpCounter(diff: any, data: any) {
    let msec = diff;
    let min = Math.floor(msec / (1000 * 60));
    if (min < 15) {
      let remainingTime = 15 - min;
      this.toastr.error(`Please try again after ${remainingTime} minute(s)`);
      this.resenddata = true;
    } else {
      localStorage.removeItem("currentTime");
      this.counter = 0;
      this.onOtpSend(data, 2);
    }
  }

  getItem(key: string): any {
    try {
      let decryptData = this.crypto.decryptObj(localStorage.getItem(key));
      return decryptData
        ;
    } catch (err) {
    }
  }
  password: any
  show = false;
  confirmshow: boolean = false;
  confirmpassword: any
  confirm() {
    if (this.confirmpassword === 'password') {
      this.confirmpassword = 'text';
      this.confirmshow = true;
    } else {
      this.confirmpassword = 'password';
      this.confirmshow = false;
    }
  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }


  timer(time: any) {
    let min: any = Math.floor(time / 60);
    let sec: any = time % 60;

    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;
    this.data3 = min + ":" + sec;
    time -= 1;

    if (time >= 0 && this.timerOn) {
      this.resenddata = true;
      setTimeout((x) => {
        this.timer(time);
      }, 1000);
      return;
    } else {
      this.data3 = "";
      this.resenddata = false;
    }

    if (!this.timerOn) {
      // Do validate stuff here
      return;
    }
  }
}
