<div class="row">
  <div class=" col-md-12 modal-header pb-0 text-end">
    <button class="button-cls" (click)="closeDialog()">
      <img src="assets/images/arrow_close.svg">
    </button>
  </div>
</div>
<div class="modal-mid pb-0 d-flex" *ngIf="data?.images">
  <button class="button-close" mat-button [mat-dialog-close]="false">
    <img [src]=data?.images width="350px">
  </button>
</div>
<div class="modal-mid pb-0 " *ngIf="data?.img">
  <button class="button-close" mat-button [mat-dialog-close]="false">
    <img [src]=data?.img width="50px">
  </button>
</div>
<div class="modal-body pt-0" *ngIf="data?.bigheading">
  <!-- <img src="assets/images/log_out.png" class="img"> -->
  <h2 class="h2-cls"> {{data?.bigheading}}</h2>
  <!-- <h3 class="pt-2 px-3"> Do you want to Log Out? </h3> -->
  <p class="m-0">{{data?.ques}} </p>
  <p class="m-0">{{data?.sol}} </p>
</div>
<div class="modal-body pt-0 mt-3" *ngIf="data?.heading">
  <!-- <img src="assets/images/log_out.png" class="img"> -->
  <h4 class=""> {{data?.heading}}</h4>
  <!-- <h3 class="pt-2 px-3"> Do you want to Log Out? </h3> -->
  <p>{{data?.para}} </p>

</div>
<div class="modal-footer mt-4" *ngIf="data?.button" (click)="enquiry()">
  <button type="button" class="btn cancel enquiry">{{data?.button}} </button>
</div>
<div class="modal-footer d-flex justify-content-between mt-4">
  <button *ngIf="data?.cancel" type="button" class="btn report me-md-2 me-0" [mat-dialog-close]="false">{{data?.cancel}}
  </button>
  <button *ngIf="data?.report" type="button" class="btn cancel ms-md-2 ms-0 mt-md-0 mt-3" [mat-dialog-close]="true"
    (click)="logout()">
    {{data?.report}}</button>
</div>

<!-- (click)="toggleSelection(item.id)" -->
<div class="row" *ngIf="data?.people?.result">
  <div class="col-md-3 mb-4" *ngFor="let item of data?.people?.result; let i = index">
    <div class="normalbox" [class.selected]="selectedData.productIds.includes(item.id)"
      (click)="toggleSelection(item.id)">
      <div>
        <div class="normalboximg" *ngIf="item">
          <span *ngIf="item?.is_sold == true" class="newtick">Sold Out</span>
          <img *ngIf="item.Media && item.Media.length > 0" [src]="item.Media[0]?.url" class="img-fluid normalimg"
            alt="{{ item.title }}" />
        </div>
        <div class="normalboxDetail">
          <h5>{{
            item?.Category3?.title == 'Other' ? item?.category3_name :
            item?.Category3?.title | titlecase}}</h5>
          <p class="location d-flex align-items-center"
            *ngIf="item?.stateDetails?.name != 'null' && item?.stateDetails?.name != null ">
            <span><img src="assets/images/locations.svg" class="img-fluid me-2" alt="location"></span>
            <span>{{
              item.cityDetails?.city | titlecase}}<span
                *ngIf="item?.cityDetails?.city != null && item?.cityDetails?.city != 'null'">, </span> {{
              item.stateDetails?.name | titlecase}}</span>
          </p>
          <div class="price" *ngIf="item?.price != 'null' && item?.price != null">
            {{ item?.category1_id == '9595821e-9d59-4218-b516-ed77d4aa7a76' ? 'Max Loan Up To' : 'Price' }}
            <span> ₹{{ item?.price | number:'1.0-0' }}</span>
          </div>

          <div class="ContactSeller mt-2">
            <a>Contact the Seller</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 text-center" *ngIf="data?.quote">
    <span class="getquotesbtn" [class.active]="(selectedData?.productIds?.length || 0) > 0"
      [class.disabled]="(selectedData?.productIds?.length || 0) === 0"
      (click)="(selectedData?.productIds?.length || 0) > 0 ? getSelectedQuotes() : null">
      Get Quote
    </span>
  </div>

</div>

<div class="row" *ngIf="data?.enquiry?.result">
  <div class="col-md-3 mb-4" *ngFor="let item of data?.enquiry?.result; let i = index">
    <div class="normalbox" [class.selected]="selectedData.productIds.includes(item.id)"
      (click)="toggleSelection(item.id)">
      <div>
        <div class="normalboximg" *ngIf="item">
          <span *ngIf="item?.is_sold == true" class="newtick">Sold Out</span>
          <img *ngIf="item.Media && item.Media.length > 0" [src]="item.Media[0]?.url" class="img-fluid normalimg"
            alt="{{ item.title }}" />
        </div>
        <div class="normalboxDetail">
          <h5>{{
            item?.Category3?.title == 'Other' ? item?.category3_name :
            item?.Category3?.title | titlecase }}</h5>
          <p class="location d-flex align-items-center"
            *ngIf="item?.stateDetails?.name != 'null' && item?.stateDetails?.name != null ">
            <span><img src="assets/images/locations.svg" class="img-fluid me-2" alt="location"></span>
            <span>{{
              item.cityDetails?.city | titlecase}}<span
                *ngIf="item?.cityDetails?.city != null && item?.cityDetails?.city != 'null'">, </span> {{
              item.stateDetails?.name | titlecase}}</span>
          </p>
          <div class="price" *ngIf="item?.price != 'null' && item?.price != null && item?.price != 0">
            {{ item?.category1_id == '9595821e-9d59-4218-b516-ed77d4aa7a76' ? 'Max Loan Up To' : 'Price' }}
            <span>₹{{ item?.price | number:'1.0-0' }}</span>
          </div>

          <div class="ContactSeller mt-2">
            <a>Contact the Seller</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 text-center">
    <span class="getquotesbtn" [class.active]="(selectedData?.productIds?.length || 0) > 0"
      [class.disabled]="(selectedData?.productIds?.length || 0) === 0"
      (click)="(selectedData?.productIds?.length || 0) > 0 ? getSelectedQuotes() : null">
      Get Quote
    </span>
  </div>
</div>

<div class=" pb-5"></div>