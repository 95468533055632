import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
import { AddNewComponent } from 'src/app/shared/dialogs/add-new/add-new.component';
import { AddpostComponent } from 'src/app/shared/dialogs/addpost/addpost.component';
import { EditPopupComponent } from 'src/app/shared/dialogs/edit-popup/edit-popup.component';

@Component({
  selector: 'app-profile-front-page',
  templateUrl: './profile-front-page.component.html',
  styleUrls: ['./profile-front-page.component.scss']
})
export class ProfileFrontPageComponent implements OnInit {
  isShow = false
  itemsList = [
    {
      list: 'Dashboard',
      img: 'assets/images/icons/apps.svg',
      pathModule: RouteConstant.Dashboard
    },
    {
      list: 'My Profile',
      img: 'assets/images/icons/user.svg',
      pathModule: RouteConstant.about
    },
    {
      list: 'My Listings',
      img: 'assets/images/icons/listings.svg',
      pathModule: RouteConstant.MyListings
    },
    {
      list: 'My Wishlist',
      img: 'assets/images/icons/wishlist.svg',
      pathModule: RouteConstant.MyWishlist
    },

    {
      list: 'Requirements',
      img: 'assets/images/icons/requirements.svg',
      pathModule: RouteConstant.Requirements
    },

    {
      list: 'Enquiries',
      img: 'assets/images/icons/enquiries.svg',
      pathModule: RouteConstant.Enquiry
    },
    {
      list: 'Settings & Preferences',
      img: 'assets/images/icons/settings.svg',
      pathModule: RouteConstant.ProfileFeaturedProducts
    },
    // {
    //   list:'Capacity',
    //   pathModule: RouteConstant.ProfileCapacity
    // }
  ]


  users = [
    {
      img: '.assets/images/save_2.svg',
      heading: 'Saved Requirement',
      para: 'Pellentesque vivamus nulla est, '
    },
    {
      img: '.assets/images/heart.svg',
      heading: 'Interests',
      para: 'Pellentesque vivamus nulla est'
    },
    {
      img: '.assets/images/setting-2.svg',
      heading: 'Settings',
      para: 'Pellentesque vivamus nulla est'
    },
    {
      img: '.assets/images/key.svg',
      heading: 'Change Password',
      para: 'Pellentesque vivamus nulla est'
    },
    {
      img: '.assets/images/vector_2.svg',
      heading: 'Privacy Policy',
      para: 'Pellentesque vivamus nulla est'
    },
    {
      img: '.assets/images/simcard-2.svg',
      heading: 'Terms of Use',
      para: 'Pellentesque vivamus nulla est'
    },
  ]
  myProfile: boolean = true;
  // activeItem: boolean = false;
  // selected: any;

  constructor(private dialog: MatDialog, private router: Router, private storageService: LocalStorageProvider) { }

  showdropdown() {
    this.isShow = !this.isShow
  }

  ngOnInit(): void {
    if (this.storageService.getItem("currentUser") == "other") {
      this.myProfile = false;
    }
    else {
      this.myProfile = true;
    }

  }

  logout() {
    const dialogRef = this.dialog.open(EditPopupComponent, {
      maxHeight: '100vh',
      width: '465px',
      data: {
        img: '../.assets/images/report.svg',
        heading: 'Are you sure you want to logout?',
        para: 'Lorem Ipsum is simply dummy text of the printing text of the printing. ',
        report: 'Yes, Logout',
        cancel: 'Cancel'
      }
    })
  }

  addNew() {
    if (this.router.url == '/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.projectsList) {
      this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.addProject])
    }
    else if (this.router.url == '/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.ProfileFeaturedProducts) {
      this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.addFeaturedProduct])
    }
    else if (this.router.url == '/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.ProfileCapacity) {
      this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.addCapacity])
    }
    else {
      const dialogRef = this.dialog.open(AddpostComponent, {
        maxHeight: '100vh',
        width: '501px',
        panelClass: 'addNew',
        data: {}
      })
    }
  }
}
