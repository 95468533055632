import { Component } from '@angular/core';
import { MessagingService } from './services/messaging.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ApiServicesService } from './services/apiServices/api-services.service';
import { API_ROUTES } from './core/_constants/api-route.constant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocalStorageProvider } from './services/storage/storage.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { RouteConstant } from './core/_constants/route.constant';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'raftaarr';
  message: any;
  ipAddress: any;
  event: Subscription;
  token: any;
  constructor(private messagingService: MessagingService, private router: Router, private Location: Location, private activeRoute: ActivatedRoute,
    private service: ApiServicesService, private angularFireMessaging: AngularFireMessaging, private loader: NgxUiLoaderService, private storageService: LocalStorageProvider,
    private http: HttpClient, private commonService: CommonService,) { }

  ngOnInit() {
    this.token = localStorage.getItem('stoken');

    // if (!this.token) {
    //   this.storageService.setItem("guestUser", "yes");
    // } else {
    //   localStorage.removeItem("guestUser");
    // }

    this.activeRoute.queryParams.subscribe((params) => {
      if (this.storageService.getItem("stoken")) {
        if (params.post_id_share || params.post_id) {
          let id = params.post_id_share ? params.post_id_share : params.post_id
          this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.home + '/' + RouteConstant.postDetailScreen], { queryParams: { post_id: id, status: "ext" } })
        }
        else if (params.requirement_id_share || params.requirement_id) {
          let id = params.requirement_id_share ? params.requirement_id_share : params.requirement_id
          this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.home + '/' + RouteConstant.requirementDetail], { queryParams: { id: id, status: "ext" } });
        }
        else if (params.product_id_share || params.product_id) {
          let id = params.product_id_share ? params.product_id_share : params.product_id
          this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.marketPlaceList + '/' + RouteConstant.marketplaceProductDetail], { queryParams: { productId: id, status: "ext" } })
        }
        else if (params.project_id_share || params.project_id) {
          let id = params.project_id_share ? params.project_id_share : params.project_id
          this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.projectDetail], { queryParams: { projectId: id, status: "ext", myId: params.my_id } })
        }
        else if (params.project_id_share || params.project_detail) {
          let id = params.project_id_share ? params.project_id_share : params.project_detail
          this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.projectDetail], { queryParams: { projectId: id, status: "ext1" } })
        }
        else if (params.featured_product_id_share || params.product_detail) {
          let id = params.featured_product_id_share ? params.featured_product_id_share : params.product_detail
          this.router.navigate(['/' + RouteConstant.main + '/' + RouteConstant.profile + '/' + RouteConstant.featuredProductDetail], { queryParams: { id: id, status: "ext" } })
        }
      }

      // this.editId = params?.['id'];
    })
    sessionStorage.setItem('deviceToken', "EMPTY");
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
      })

    // this.commonService.getRefreshData();
  }

  // requirementDetail(id:any){
  //   this.loader.start();
  //   let dataToPost={
  //     requirement_id:id//"a8ff6adf-3410-4437-bda5-694837abd86c"
  //   }
  //   this.service.get(dataToPost,API_ROUTES.MyRequirements.getRequirementDetail).pipe().subscribe((res=>{
  //     this.loader.stop();

  //     if (res.success) {
  //       let data = res.result;
  //       if(data.req_type.toLowerCase() == 'special') {
  //         this.router.navigate(['main/home/other_home'], {queryParams: {id: id,status:"ext"}});
  //       }
  //       else if(data.req_type.toLowerCase() == 'order requirement') {
  //         this.router.navigate(['/main/home/manufacturing-o-r_home'], {queryParams: {id: id,status:"ext"}});
  //       }
  //       else if(data.req_type.toLowerCase() == 'hr') {
  //         this.router.navigate(['/main/home/hr-requirement_home'] , {queryParams: {id: id,status:"ext"}});
  //       }
  //       else if(data.req_type.toLowerCase() == 'machine') {
  //         this.router.navigate(['/main/home/machine_home'] , {queryParams: {id: id,status:"ext"}});
  //       }
  //       else if(data.req_type.toLowerCase() == 'raw material') {
  //         this.router.navigate(['/main/home/raw-mat_home'] , {queryParams: {id: id,status:"ext"}});
  //       }
  //       else if(data.req_type.toLowerCase() == 'finance') {
  //         this.router.navigate(['/main/home/finance_home'] , {queryParams: {id: id,status:"ext"}});
  //       }

  //     } else {
  //     }

  //   }))
  // }

  ngOnDestroy() {
    this.event.unsubscribe();
  }

}
