<div class="modal-header pt-0 pb-0">
    <button class="button-close mb-2" mat-button [mat-dialog-close]="false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/>
        </svg>
    </button>
</div>
<!-- <div class="modal-body pt-0">
    <h1 class="">I’m Interested</h1>
    <p class="text-start">What is the maximum amount are you willing to pay this product?</p>
    <div class="mt-3 d-flex inrinput justify-content-between align-items-center" >
       <label class="text-center inrlabel">INR</label>
        <input type="text"  appDigitOnly pattern="^[1-9][0-9]*$" maxlength="9" [(ngModel)]="amount" placeholder="Amount here (Optional)" class="ms-3" >
    </div>
    
   
</div> -->
<div class="modal-body pt-0">
    <h1 class="">I’m Interested</h1>
    <p class="text-start">What is the maximum amount are you willing to pay for this product?</p>
    <div class="mt-3 d-flex inrinput justify-content-between align-items-center">
        <label class="text-center inrlabel">INR</label>
        <input type="text" appDigitOnly pattern="^[1-9][0-9]*$" maxlength="9" [(ngModel)]="amount" placeholder="Amount here (Optional)" class="ms-3" oninput="this.value = this.value.replace(/[^0-9]/g, '');">
    </div>
</div>

<div class="d-flex justify-content-end align-items-center mt-3">
    <div class="d-flex align-items-center">
        <p class="mb-0 loan-required">Loan Required</p>
        <input class="ms-2" [(ngModel)]="loanRequired" type="checkbox">
    </div>
</div>
  

<div class="modal-footer d-flex justify-content-between mt-4">
    <!-- <button type="button" class="btn report me-2" [mat-dialog-close]="false">Skip</button> -->
    <button type="button" class="btn cancel ms-2" (click)="send()">Send Request</button>
</div>
