import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConst } from 'src/app/core/_constants/app.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { ValidationsService } from 'src/app/services/validators/validator';
import { PostPublishComponent } from 'src/app/shared/dialogs/post-publish/post-publish.component';
import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { DeviceUUID } from 'device-uuid';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from 'src/app/services/data.service';
// import { SocialAuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SignInService } from 'src/app/services/sign-in.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelect } from '@angular/material/select';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { LoginComponent } from "src/app/auth/login/login.component";
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  linkedInCredentials = {
    clientId: "77zfe6wdghbp0p",
    redirectUrl: "https://raftaarr.com/login",
    scope: "r_liteprofile%20r_emailaddress" // To read basic user profile data and email
  };
  @ViewChild('select') private select: MatSelect;
  loginForm: any;
  submitted: boolean = false;
  countnumber: any = "";
  data: any;
  business_activity: any = [];
  docList = [];
  test: any;
  categories: any;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  uuid = new DeviceUUID().get();
  deviceInfo = this.deviceService.getDeviceInfo();
  currentDate = new Date();
  password: any
  show = false;
  confirmshow: boolean = false;
  confirmpassword: any
  businessActivityOpened: any;
  selectedBusinessActivity: any = [];
  showPhoneNumberWarning: boolean;
  constructor(private fb: FormBuilder, public validators: ValidationsService,
    private service: ApiServicesService,
    private router: Router,
    private storageService: LocalStorageProvider,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private dataService: DataService,
    private toastr: ToastrService, public dialogRef: MatDialog,
    private dialogs: MatDialogRef<SignupComponent>,
    // private authService:SocialAuthService,
    private sign: SignInService) { }

  // emailPattern = "^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,4}$";
  // passwordPattern= "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$"
  ngOnInit(): void {
    this.password = 'password';
    this.confirmpassword = 'password';
    // this.showPhoneNumberWarning = false;
    //this.getIndustry();
    // console.log(localStorage);
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      phone_no: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
      country_code: ['+91'],
      device_token: localStorage.getItem('deviceToken'),
      // device_token: sessionStorage.getItem('deviceToken'),
      device_id: localStorage.getItem('device_Id')?.toString(),
      // device_id: sessionStorage.getItem('device_Id')?.toString(),
      device_type: this.deviceInfo.deviceType,
    },
      {
        validator: ConfirmPasswordValidator("password", "confirm_password")

      }
    );

    // Subscribe to value changes of password and confirm password fields
    // this.loginForm.get('password').valueChanges.subscribe(() => {
    //   this.updateConfirmPasswordValidation();
    // });
    // this.loginForm.get('confirm_password').valueChanges.subscribe(() => {
    //   this.updateConfirmPasswordValidation();
    // });



    // this.loginForm.valueChanges.subscribe(() => {
    //   this.select.close();
    // });
  }
  closeDialog(): void {
    this.dialog.closeAll(); // Pass data when closing
  }
  updateConfirmPasswordValidation() {
    const passwordControl = this.loginForm.get('password');
    const confirmPasswordControl = this.loginForm.get('confirm_password');

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ 'passwordsNotMatch': true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  }

  // get f() {
  //   return this.loginForm.controls;

  // }
  confirm() {
    if (this.confirmpassword === 'password') {
      this.confirmpassword = 'text';
      this.confirmshow = true;
    } else {
      this.confirmpassword = 'password';
      this.confirmshow = false;
    }
  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  Login() {
    this.dialogs.close();
    const screenWidth = window.innerWidth;
    let isSmallScreen = true;
    if (screenWidth <= 1440) {
      isSmallScreen = true;
    }
    const dialogRef = this.dialogRef.open(LoginComponent, {
      maxHeight: '100vh',
      width: isSmallScreen ? '900px' : '900px',
    })

  }
  onSubmit(post: any) {
    this.submitted = true;
    // console.log(post);
    if (this.loginForm.valid) {
      this.service.post(post, `${API_ROUTES.Account.signup}`, {}).pipe().subscribe((res) => {
        console.log(res);
        if (res.success) {
          this.toastr.success("Account Created.");
          localStorage.removeItem("guestUser");
          localStorage.setItem(
            "stoken",
            res.result.token
          );
          localStorage.setItem(
            "refresh_token",
            res.result.refresh_token
          );
          this.router.navigate([RouteConstant.mainhome]);
          window.location.reload();
        }
      },
        (error) => {
          this.toastr.error(error.message);
          console.log(error);
        })
    } else {
      this.toastr.error('Invalid Form');
      console.log('error in submitting');
    }
  }
  // let businessActivity: any = [];
  // this.showPhoneNumberWarning = false;
  // for(let i =0; i<this.business_activity.length;i++){
  //   let obj = {
  //     title: this.business_activity[i].industry_name,
  //     business_activity: this.business_activity[i].id
  //   }
  //   businessActivity.push(obj)
  // }
  // if (this.selectedBusinessActivity && this.selectedBusinessActivity.length > 0) {
  //   let filterData = this.data.filter((val: any) => this.selectedBusinessActivity.includes(val.id))

  //   if (filterData && filterData.length > 0) {
  //     filterData.forEach((val: any) => {
  //       let obj = {
  //         business_activity: val.id,
  //         title: val.industry_name
  //       }
  //       businessActivity.push(obj);
  //     })
  //   }


  // }

  // post.business_activity = businessActivity;

  // var dd = JSON.stringify(post);
  // post.phone_no = parseInt(post.phone_no);

  // this.storageService.setItem('stoken', res.result.token)

  // this.storageService.setItem('refresh_token', res.result.refresh_token)

  // this.storageService.setItem('phone', res.result) // dk what the use of this key

  // this.weightage();
  // if (res.result.phone_no) {
  //   this.storageService.setItem('phoneNumber', res.result.phone_no);
  // }
  // this.dataService.setData('loginPhone', post['phone_no']);
  // this.dataService.setData('loginEmail', post['email']);
  // if (res.result.status === "1") {
  //   this.router.navigate([RouteConstant.PhoneOtp], { queryParams: { type: 1 } })
  // }
  // else if (res.result.step == 0){
  //   this.router.navigateByUrl('/' + RouteConstant.main + '/' + RouteConstant.home);
  // }


  // }



  // }
  // getIndustry() {
  //   this.service.get({}, `${API_ROUTES.Account.getIndustry}`).pipe().subscribe((res) => {
  //     this.data = res.result;

  //   })
  // }
  // getCategory(id: any) {
  //   let catagory = {
  //     industry_id: id
  //   }
  //   this.service.get(catagory, `${API_ROUTES.Account.getCategory}`).pipe().subscribe((res) => {
  //     //if(res.result.rows.length > 1){
  //     this.categories = res.result.rows;
  //     //}


  //   })
  // }

  // registered(msg: any) {
  //   const screenWidth = window.innerWidth;
  //   let isSmallScreen = false;
  //   if (screenWidth <= 1440) {
  //     isSmallScreen = true;
  //   }

  //   const dialogRef = this.dialog.open(PostPublishComponent, {
  //     maxHeight: '100vh',
  //     width: isSmallScreen ? '300px' : '465px',
  //     panelClass: 'resetPassword',
  //     data: {
  //       img: 'assets/images/info-circle_red.svg',
  //       heading: msg,
  //       // title: 'Please check your inbox and click in the recieved link to reset a password',
  //       image: 'assets/images/logout_2.svg',
  //       btn: 'Back to login'
  //     }
  //   })

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.router.navigate(['/'])
  //     }
  //   });
  // }

  // businessActivityOpen(ev: any) {


  //   this.businessActivityOpened = ev;
  //   if (this.businessActivityOpened == false && this.selectedBusinessActivity.length > 0) {
  //     this.getCategory(this.selectedBusinessActivity[0])
  //     // this.selectedBusinessActivity.forEach((id:any)=>{
  //     //   this.getIndustry(id);
  //     // })
  //   }
  //   else if (this.businessActivityOpened == false && this.selectedBusinessActivity.length == 0) {
  //     this.categories = [];
  //   }

  // }

  // fix(event: any) {
  //   this.selectedBusinessActivity = event.value;
  // let findFlag;
  // if(event.value.length == this.data.length){
  //   this.select.close();
  // }
  // this.docList = [];
  // event.value.forEach((val:any)=>{
  //   if(val.id!=null){
  //     let obj = this.business_activity.find((item:any) =>
  //       item.industry_name == val.industry_name
  //     );
  //       if (obj) {
  //         // findFlag = true;
  //       } else {
  //         // findFlag = false;
  //         this.business_activity.push(val);
  //         this.getCategory(val.id);

  //       }
  //   }
  // });


  // }
  // fixCategory(id: any) {


  //   this.loginForm.value.category1_id = id;

  // }

  // googleSignin() {
  //   console.log("hiihihihihihihih hihihhhi hhihihh hihihhi hihihih hihihh ihih")

  //   let deviceToken = sessionStorage.getItem('deviceToken');
  //   let deviceId = sessionStorage.getItem('device_Id')?.toString();
  //   this.sign.GoogleAuth(1, deviceToken, deviceId, this.deviceInfo.deviceType);
  // }

  // facebookSignIn() {
  //   let deviceToken = sessionStorage.getItem('deviceToken');
  //   let deviceId = sessionStorage.getItem('device_Id')?.toString();
  //   // this.sign.FacebookAuth(2,this.uuid + this.currentDate,this.uuid,this.deviceInfo.deviceType);
  //   this.sign.FacebookAuth(2, deviceToken, deviceId, this.deviceInfo.deviceType);
  // }

  // linkedIn() {
  //   window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedInCredentials.clientId
  //     }&redirect_uri=${this.linkedInCredentials.redirectUrl}&scope=${this.linkedInCredentials.scope}`;
  // }

  // passwordField(evt: any) {

  //   //var e = evt || window.event;
  //   var charCode = (evt.which) ? evt.which : evt.keyCode

  //   if (charCode != 32)
  //     return true;
  //   return false;
  // }

  // weightage() {
  //   let data = {
  //     company_name: true,
  //     description: false,
  //     address: false,
  //     industry_id: true,
  //     category1_id: true,
  //     categoryl5_id: false,
  //     gst_no: false,
  //     company_size: false,
  //     email: true,
  //     phone_no: true,
  //     total_machine: false,
  //     incorporated_year: false,
  //     state: false,
  //     city: false,
  //     lastyear_turnover: false,
  //     constitution_of_business: false,

  //   }
  //   this.service.post(data, `${API_ROUTES.More.weightage}`, {}).pipe().subscribe((res) => {

  //     if (res.success) {

  //     }



  //   },
  // (error)=>{
  //   this.toastr.error(error.result.error)
  // }
  // )
  // }
}



