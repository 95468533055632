<!-- new sign up page -->
<div class="row">
    <div class=" col-md-12 d-block pb-0 text-end">
        <button class="button-cls" (click)="closeDialog()">
            <img src="assets/images/arrow_close.svg">
        </button>
    </div>
</div>
<div class="container p-0 mainnn">
    <div class="row m-0 d-flex  justify-content-center bg-color">
        <div class="col-lg-12 reset loginpagenew">
            <h3 class="mt-4 mb-0  loginaccount">Create an account</h3>
            <form class="mt-4 w-90" [formGroup]="loginForm" (submit)="onSubmit(loginForm.value)">
                <div class="phonedetail d-grid ">
                    <div class="customwidth position-relative mt-2">
                        <input type="email" class="form-control emaildata ps-3" formControlName="email" maxlength="50"
                            (keydown)="validators.avoidSpace($event)" placeholder="Enter Email">

                    </div>

                    <div class="customwidth position-relative  mt-3">
                        <p class="position-absolute c_input border-end pe-2 me-2">+91 </p>
                        <input type="text" class="form-control countrycode" formControlName="phone_no" maxlength="10"
                            placeholder=" Enter Mobile Number" appDigitOnly>
                    </div>
                    <small *ngIf="submitted && loginForm.controls.phone_no.invalid" class="alert py-0 mb-0">
                        <small *ngIf="loginForm.controls['phone_no'].errors.required">
                            Phone number is required.
                        </small>
                        <small *ngIf="loginForm.controls['phone_no'].errors.pattern">
                            Phone number must contains valid numbers.
                        </small>
                    </small>

                    <div class="customwidth position-relative mt-3">
                        <div class="cursor-pointer" (click)="onClick()">
                            <img class="securepassword" *ngIf="!show" src="assets/images/new_img/unlock.svg" alt=""
                                width="20px" height="20px">
                            <img class="securepassword smsdata1" *ngIf="show" src="assets/images/new_img/unlock.svg"
                                alt="" width="20px" height="20px">
                        </div>
                        <!-- <img class="securepassword" src="assets/images/lock@1x.png" alt="" (click)="hidePassword = !hidePassword"> -->
                        <input [type]="password" class="form-control emaildata ps-3" maxlength="16"
                            (keydown)="validators.avoidSpace($event)" placeholder="Enter Password"
                            formControlName="password">
                        <small *ngIf=" submitted && loginForm.controls['password'].invalid " class="alert">
                            <small *ngIf="submitted && loginForm.controls['password'].errors.required">
                                Password is required
                            </small>
                        </small>
                    </div>

                    <div class="customwidth position-relative mt-3">
                        <div class="cursor-pointer" (click)="confirm()">
                            <!-- {{confirmshow | json}} -->
                            <img class="securepassword" *ngIf="!confirmshow" src="assets/images/new_img/unlock.svg"
                                alt="" width="20px" height="20px">
                            <img class="securepassword smsdata1" *ngIf="confirmshow"
                                src="assets/images/new_img/unlock.svg" alt="" width="20px" height="20px">
                        </div>
                        <!-- <img class="securepassword" src="assets/images/lock@1x.png" alt="" (click)="hidePassword = !hidePassword"> -->
                        <input [type]="confirmpassword" class="form-control emaildata ps-3" maxlength="16"
                            (keydown)="validators.avoidSpace($event)" placeholder="Re-Enter Password"
                            formControlName="confirm_password">
                        <small *ngIf=" submitted && loginForm.controls['confirm_password'].invalid " class="alert">
                            <small *ngIf="submitted && loginForm.controls['confirm_password'].errors.required">
                                Password is required
                            </small>
                        </small>
                    </div>
                    <div class="customwidth mt-3 d-flex">
                        <input type="checkbox">
                        <p class="ms-2 noaccount mt-3"> I accept the
                            <button type="button" class="createaccount" [title]="'Terms of Conditions'">Terms of
                                Conditions </button> & <button type="button" class="createaccount"
                                [title]="'Privacy Policy'">Privacy Policy</button>
                        </p>
                    </div>
                </div>
                <div class="text-center mt-3" [title]="'Create Account'">
                    <button type="submit" class="loginbtn">Create Account</button>
                </div>
            </form>

            <div class="text-center mt-3">
                <p class="noaccount">Already have an account?
                    <button type="button" class="createaccount" [title]="'Log In'" (click)="Login()">Log In</button>
                </p>
            </div>
        </div>
    </div>
</div>




<!-- old sign up page -->
<!-- <div class="container-fluid p-0 signup">
    <div class="row m-0 d-flex justify-content-between bg-color">
        <div class="col-lg-6 p-0  sideimg">
            <div class="signupback">
                <img src="assets/images/auth.png">
            </div>
        </div>
        <form class="col-lg-6 pt-md-5 pt-3 px-md-5 mx-0 m-auto right" [formGroup]="loginForm"
            (ngSubmit)="onSubmit(loginForm.value)">
            <img class="logo" src="assets/images/raftaarrlogo.png" alt="">
            <h4 class="mt-4">Create an account</h4>
       

            <div>

                <div class="phonedetail d-grid ">
                    <div class="w-100  mt-2">
                        <input type="text" class="form-control companydetail" placeholder="Enter company name"
                            maxlength="50" appAlphaNumOnly formControlName="company_name">
                       
                        <small *ngIf="submitted && loginForm.controls['company_name'].invalid " class="alert">
                            <small *ngIf="loginForm.controls['company_name'].errors.required">
                                Company name is required
                            </small>

                        </small>
                    </div>
                </div>



                <div class="phonedetail position-relative d-grid ">
                    
                    <div class="w-100  mt-3 d-flex flex-column">
                        <mat-form-field appearance="fill" class="">
                            <mat-select #select placeholder="Select Business activity"
                                formControlName="business_activity" (selectionChange)="fix($event)" panelClass="ba1"
                                (openedChange)="businessActivityOpen($event)" multiple>
                                <mat-option *ngFor="let items of data" [value]="items.id">{{items.industry_name}}
                                </mat-option>
                            </mat-select>
                            

                        </mat-form-field>
                        <small *ngIf="submitted && loginForm.controls['business_activity'].invalid "
                            class="alert select-box-alert">
                            <small *ngIf="loginForm.controls['business_activity'].errors.required">
                                Business activity is required
                            </small>
                        </small>
                    </div>
                    <div class="w-100  mt-3 d-flex flex-column">
                        <mat-form-field appearance="fill" class="">
                            <mat-select placeholder="Select industry" formControlName="category1_id" panelClass="i1"
                                (change)="fix($event)">
                                <mat-option *ngFor="let items of categories" [value]="items.id">{{items.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <small *ngIf="submitted && loginForm.controls['category1_id'].invalid "
                            class="alert select-box-alert">
                            <small *ngIf="loginForm.controls['category1_id'].errors.required">
                                Industry is required
                            </small>
                        </small>

                    </div>
                </div>


                <div class="phonedetail d-grid  mt-3">

                    <div class="w-100 position-relative">
                        <img class="smsdata" src="assets/images/sms-edit.svg" alt="">
                        <input type="text" class="form-control companydetail"
                            placeholder="Enter Company's email address" maxlength="50" formControlName="email"
                            autocomplete="new-email">
                        
                    </div>
                    <small *ngIf="submitted && loginForm.controls['email'].invalid" class="alert py-0 mb-0">
                        <small *ngIf="loginForm.controls['email'].errors.required">
                            Email is required
                        </small>
                        <small *ngIf="loginForm.get('email').errors.pattern">
                            Please enter valid email address
                        </small>
                    </small>
                </div>


                <div class="phonedetail d-grid ">
                    <div class="w-100 position-relative  mt-3">
                        <p class="position-absolute c_input ps-2">+91 <span class="text-muted ">|</span></p>

                        <p class="counternumber px-2 text-center"><span
                                *ngIf="loginForm.controls.phone_no.value.length<=9 && loginForm.controls.phone_no.value.length>0">0</span>{{loginForm.controls.phone_no.value.length}}/10
                        </p>
                        <input type="text" class="form-control countrycode" maxlength="10"
                            placeholder="Enter phone number" formControlName="phone_no" autocomplete="off" appDigitOnly>
                        
                    </div>
                    <small *ngIf="submitted && loginForm.controls['phone_no'].invalid" class="alert py-0 mb-0">
                        <small *ngIf="loginForm.controls['phone_no'].errors.required">
                            Phone number is required
                        </small>
                    </small>
                    
                </div>



                

                <div class="phonedetail  d-grid mt-3">



                    <div class="w-100 position-relative">
                        <input [type]="password" class="form-control companydetail" placeholder="Enter new password"
                            maxlength="16" formControlName="password" autocomplete="new-password">
                       
                        <div class="cursor-pointer" (click)="onClick()">
                            <img class="smsdata" *ngIf="!show" src="assets/images/lock@1x.png" alt="" width="20px"
                                height="20px">
                            <img class="smsdata smsdata1" *ngIf="show" src="assets/images/unlock.png" alt=""
                                width="20px" height="20px">
                        </div>
                    </div>
                    <small [class]="loginForm.get('password').errors?.pattern?'mt-1':'password-message mt-1'">
                        * Password can contain alphabets,numbers,special characters and must be of 8 characters
                    </small>
                    <small *ngIf="submitted && loginForm.controls['password'].invalid" class="alert py-0 mb-0">
                        <small *ngIf="submitted && loginForm.controls['password'].errors.required">
                            Password is required
                        </small>
                    </small>

                </div>

                <div class="phonedetail d-grid  mt-3">
                    <div class="w-100 position-relative">
                        <input [type]="confirmpassword" class="form-control companydetail"
                            placeholder="Enter confirm password" maxlength="16" formControlName="confirm_password"
                            autocomplete="new-password">
                        
                        <div class="cursor-pointer" (click)="confirm()">
                            <img class="smsdata" *ngIf="!confirmshow" src="assets/images/lock@1x.png" alt=""
                                width="20px" height="20px">
                            <img class="smsdata smsdata1" *ngIf="confirmshow" src="assets/images/unlock.png" alt=""
                                width="20px" height="20px">
                        </div>

                    </div>
                    <small *ngIf="submitted && loginForm.controls['confirm_password'].invalid " class="alert py-0 mb-0">
                        <small *ngIf="submitted && loginForm.controls['confirm_password'].errors.required">
                            Confirm password is required
                        </small>
                    </small>
                    <small class="mt-1"
                        *ngIf="loginForm.get('confirm_password').hasError('passwordsNotMatch')  && (loginForm.get('confirm_password').dirty || loginForm.get('confirm_password').touched)">Passsword
                        and
                        Confirm Password didn't match. </small>
                </div>
                <div class="social-login text-center mt-4">
                    <p>
                        Or Sign up With
                    </p>
                    <button type="button" (click)="facebookSignIn()" class=" py-1 cursor"><img
                            src="assets/images/fb.svg" alt=""></button>
                    <button type="button" (click)="googleSignin()" class="mx-2  py-1"><img
                            src="assets/images/google.svg" alt=""></button>
                    <button type="button" (click)="linkedIn()" class=" py-1"><img src="assets/images/linkdin.svg"
                            alt=""></button>
                </div>
            </div>
            <div class="text-center mt-5">
                <button type="submit" class="loginbtn">Next</button>
            </div>
            <div class="text-center mt-3">
                <p class="noaccount">Already a Raftaarr member?<button routerLink="/login"
                        class="createaccount ms-2">Login</button></p>
            </div>

        </form>

    </div>

</div> -->