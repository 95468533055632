<div class="modal-header pb-0 pt-0">
    <img src="assets/images/close.png" alt="" class="cursor" [mat-dialog-close]="false">
</div>
<form [formGroup]="myForm">
<div class="modal-body pt-0">
    <img src="assets/images/reject.svg">
    <h1 class="pt-2"> Why are you rejecting this order?</h1>
   <textarea class="mt-3" type="text" maxlength="100" placeholder="Write reason for rejecting this order." formControlName="rejected">

   </textarea>
</div>
<div class="modal-footer d-flex justify-content-between pt-4">
    <button type="button" class="btn report me-1" [mat-dialog-close]="false">Cancel</button>
    <button type="button" class="btn cancel ms-1" (click)="onSubmit(myForm.value)">Reject</button>
</div>
</form>