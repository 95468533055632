import { Injectable } from '@angular/core';

declare var FB: any; // Declare Facebook SDK

@Injectable({
  providedIn: 'root'
})
export class FacebookAuthService {
  FB: any;
  constructor() {}
  loginWithFacebook() {
    this.FB.login((response: any) => {
      if (response.authResponse) {
        console.log('User logged in:', response.authResponse);
  
        // ✅ Store access token in localStorage
        localStorage.setItem('fb_access_token', response.authResponse.accessToken);
  
        this.getUserProfile(response.authResponse.accessToken);
      } else {
        console.log('User cancelled login.');
      }
    }, { scope: 'email,public_profile' });
  } 
  

  getUserProfile(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      FB.login((response: any) => {
        if (response.authResponse) {
          FB.api('/me/accounts', { access_token: response.authResponse.accessToken }, (res: any) => {
            console.log('Business Accounts:', res);
          });
        }
      }, { scope: 'business_management,ads_management' });

    });
  }
}
