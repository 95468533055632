import { Injectable, NgZone } from "@angular/core";
// import { auth } from 'firebase/app';
// import { Router } from "@angular/router";
// import { AngularFireAuth } from '@angular/fire/compat/auth';
// import firebase from 'firebase/compat';
import { getAuth, signOut } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { FacebookAuthProvider } from "firebase/auth";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ApiServicesService } from "./apiServices/api-services.service";
import { API_ROUTES } from "../core/_constants/api-route.constant";
import { Router } from "@angular/router";
import { LocalStorageProvider } from "./storage/storage.service";
import { CreatePasswordComponent } from "../shared/dialogs/create-password/create-password.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { RouteConstant } from "../core/_constants/route.constant";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SignInService {
  user: any;
  deviceId: any;
  deviceToken: any;
  deviceType: any;
  type: any;
  code: any;
  linkedinAccessToken: any;
  lastName: any;
  firstName: any;
  emailId: any;
  id: any;
  constructor(
    // public router: Router,
    // public ngZone: NgZone,
    // public afAuth: AngularFireAuth,
    // private angularFireAuth: AngularFireAuth,
    private storageService: LocalStorageProvider,
    private router: Router,
    private service: ApiServicesService,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) {
    // this.afAuth.authState.subscribe((user:any) => {
    //     this.user = user;
    // })
  }

  //   OAuthProvider(provider:any) {
  //     return this.afAuth.signInWithPopup(provider)
  //         .then((res:any) => {
  //             this.ngZone.run(() => {
  //                 this.router.navigate(['dashboard']);
  //             })
  //         }).catch((error:any) => {
  //             window.alert(error)
  //         })
  // }
  // // Firebase Google Sign-in
  // SigninWithGoogle() {
  //     return this.OAuthProvider(new firebase.auth.GoogleAuthProvider())
  //         .then((res:any) => {
  //
  //         }).catch((error:any) => {
  //
  //         });
  // }
  // // Firebase Logout
  // SignOut() {
  //     return this.afAuth.signOut().then(() => {
  //         this.router.navigate(['login']);
  //     })
  // }

  // GoogleAuth(type: any, deviceToken: any, deviceId: any, deviceType: any) {
  //   debugger;
  //   this.type = type;
  //   this.deviceId = deviceId;
  //   this.deviceToken = deviceToken;
  //   this.deviceType = deviceType;
  //   return this.AuthLogin(new GoogleAuthProvider());
  // }

  async GoogleAuth(type: any, deviceToken: any, deviceId: any, deviceType: any) {
    try {
      this.type = type;
      this.deviceId = deviceId;
      this.deviceToken = deviceToken;
      this.deviceType = deviceType;

      await this.AuthLogin2(new GoogleAuthProvider());
    } catch (error) {
      console.error(error, "Error in GoogleAuth");
    }
  }

  FacebookAuth(type: any, deviceToken: any, deviceId: any, deviceType: any) {
    this.type = type;
    this.deviceId = deviceId;
    this.deviceToken = deviceToken;
    this.deviceType = deviceType;
    return this.AuthLogin(new FacebookAuthProvider());
  }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        //if(this.type == 1){
        localStorage.setItem('loginType', '1'); //to show the solial mandate popup seting the login type
        // this.storageService.setItem(
        //   "refresh_token",
        //   result.result.refresh_token
        // );
        this.SignIn(
          result,
          this.type,
          this.deviceToken,
          this.deviceId,
          this.deviceType
        );
        //}
      })
      .catch((error) => { });
  }

  async AuthLogin2(provider: any) {
    try {
      const result = await this.afAuth.signInWithPopup(provider);
      if (this.type == 1) {
        localStorage.setItem('loginType', '1');
        await this.SignIn(
          result,
          this.type,
          this.deviceToken,
          this.deviceId,
          this.deviceType
        );
      }
    } catch (error: any) {
      if (error.code === 'auth/popup-closed-by-user') {
        this.toastr.error('The login popup was closed before completing the sign-in.')
        console.warn("The login popup was closed before completing the sign-in.");
        // Optional: retry logic or provide feedback to the user
      } else {
        this.toastr.error('Firebase Error')
        console.error(error, "Firebase Error");
      }
    }
  }



  SignIn(
    data: any,
    type: any,
    deviceToken: any,
    deviceId: any,
    deviceType: any
  ) {
    this.type = type;
    this.deviceId = deviceId;
    this.deviceToken = deviceToken;
    this.deviceType = deviceType;
    // let socialType = this.type == 1? 'gid': this.type == 2? 'fid': 'lid'
    let post = {
      gid: this.type == 1 ? data.additionalUserInfo.profile.id : null,
      fid: this.type == 2 ? data.additionalUserInfo.profile.id : null,
      lid: this.type == 3 ? data.additionalUserInfo.profile.id : null,
      aid: null,
      company_name: data.additionalUserInfo.profile.name,
      email: data.additionalUserInfo.profile.email,
      device_token: this.deviceToken,
      device_id: this.deviceId,
      device_type: this.deviceType,
    };
    this.service
      .post(post, `${API_ROUTES.Account.socialLogin}`, {})
      .pipe()
      .subscribe(
        (res) => {
          // this.storageService.setItem('stoken', res.result.token)
          // this.storageService.setItem('phone', res.result)
          if (res.success) {
            localStorage.clearItem("guestUser");
            localStorage.setItem("stoken", res.result.token);
            localStorage.setItem("refresh_token2", res.result.refresh_token);

            localStorage.setItem("refresh_token", res.result.refresh_token);

            localStorage.setItem("UserId", res.result.id);
            localStorage.setItem(
              "companyInterest",
              res.result.isCompanyInterest
            );

            // this.storageService.clearItem("guestUser");
            // this.storageService.setItem("stoken", res.result.token);
            // this.storageService.setItem("refresh_token2", res.result.refresh_token);

            // this.storageService.setItem("refresh_token", res.result.refresh_token);

            // this.storageService.setItem("UserId", res.result.id);
            // this.storageService.setItem(
            //   "companyInterest",
            //   res.result.isCompanyInterest
            // );
            if (!res.result.userExist) {
              this.weightage();
            }
            // const dialogRef = this.dialog.open(CreatePasswordComponent, {
            //   maxHeight: '100vh',
            //   width: '465px',
            //   panelClass: 'yespost',
            //   data: {
            //     img: 'assets/images/addpost.png',
            //     heading: 'Are you sure you want to save this Manufacturing Order Requirement?',
            //     report: 'Back',
            //     cancel: 'Yes, Save'
            //   }
            // });
            let url = this.storageService.getItem("product-url");
            if (url) {
              const modifiedUrl = url?.replace("http://localhost:4200/", "");
              this.router.navigateByUrl(modifiedUrl);
              return;
            }
            this.router.navigateByUrl(
              "/" + RouteConstant.main + "/" + RouteConstant.home
            );

            // this.dataService.setData('loginPhone',post['phone_no']);
            // this.dataService.setData('loginEmail',post['email']);
            // this.router.navigate(['/phone-otp'], { queryParams: { type: 1 } })
          } else {
            this.toastr.error(res.message);
          }
        },
        (error) => {
          this.toastr.error(error.result.error);
        }
      );
  }
  isProduction = environment.production;
  linkedInAccessToken(deviceType: any, code: any, type: any) {
    this.deviceType = deviceType;
    let params = {
      code: code,
      redirectUrl: type,
    };
    this.service
      .post({}, `${API_ROUTES.Account.LinkedIn.getAccessToken}`, params)
      .pipe()
      .subscribe(
        (res) => {
          //  this.linkedinAccessToken = res.stoken;
          let json = JSON.parse(res.result);
          // debugger
          localStorage.setItem('loginType', '1'); //to show the solial mandate popup seting the login type
          // this.storageService.setItem("refresh_token", res.result.refresh_token);

          this.linkedinAccessToken = json.stoken;
          this.profileData();
        },
        (error) => {
          this.router.navigate(["/login"]);
        }
      );
  }

  profileData() {
    let params = {
      oauth2_stoken: this.linkedinAccessToken,
    };
    this.service
      .get(params, `${API_ROUTES.Account.LinkedIn.getUserDetail}`)
      .pipe()
      .subscribe(
        (res) => {
          //  this.linkedinAccessToken = res.stoken;
          let json = JSON.parse(res.result);

          //  this.basicData = res;
          this.lastName = json.localizedLastName;
          this.firstName = json.localizedFirstName;
          this.id = json.id;
          //
          //
          //

          this.emailData();
        },
        (error) => {
          this.router.navigate(["/login"]);
        }
      );
  }

  emailData() {
    let params = {
      oauth2_stoken: this.linkedinAccessToken,
    };

    this.service
      .get(params, `${API_ROUTES.Account.LinkedIn.getEmail}`)
      .pipe()
      .subscribe(
        (res) => {
          //  this.linkedinAccessToken = res.stoken;
          let json = JSON.parse(res.result);

          let key = "handle~";
          this.emailId = json?.elements[0][key]?.emailAddress;
          let deviceToken = localStorage.getItem("deviceToken");
          // let deviceToken = sessionStorage.getItem("deviceToken");
          let deviceId = localStorage.getItem('device_Id')?.toString();
          // let deviceId = sessionStorage.getItem("device_Id")?.toString();
          let data = {
            additionalUserInfo: {
              profile: {
                id: this.id,
                name: this.firstName + " " + this.lastName,
                email: this.emailId,
              },
            },
          };
          this.SignIn(data, 3, deviceToken, deviceId, this.deviceType);
        },
        (error) => {
          this.router.navigate(["/login"]);
        }
      );
  }

  weightage() {
    let data = {
      company_name: true,
      description: false,
      address: false,
      industry_id: false,
      category1_id: false,
      categoryl5_id: false,
      gst_no: false,
      company_size: false,
      email: true,
      phone_no: false,
      total_machine: false,
      incorporated_year: false,
      state: false,
      city: false,
      lastyear_turnover: false,
      constitution_of_business: false,
    };
    this.service
      .post(data, `${API_ROUTES.More.weightage}`, {})
      .pipe()
      .subscribe(
        (res) => {
          if (res.success) {
            this.service
              .get({}, API_ROUTES.More.weightageCalculation)
              .pipe()
              .subscribe((res) => { });
          }
        }
        // (error)=>{
        //   this.toastr.error(error.result.error)
        // }
      );
  }

  signout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.router.navigate([""]);
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }
}
