import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { LocalStorageProvider } from 'src/app/services/storage/storage.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class ApiServicesService {
  company_name: any;
  phone_no: any;
  userauth_id: any;
  getBaseUrl(): string {
    return environment.domUrls;  // Get the base URL from environment file
  }
  generateid() {
    var generateCustomId = function (length: number) {
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'; // Allowed characters for the token
      let id = '';

      // Use current timestamp in milliseconds
      const timestamp = Date.now().toString();

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters[randomIndex];
      }

      // Append part of the timestamp to the ID
      id += timestamp.slice(-5); // Use the last 5 digits of the timestamp to keep it concise

      return id;
    };
    let deviceid = generateCustomId(27);
    localStorage.setItem('device_Id', deviceid);
  }


  generateAndStoreDeviceToken() {
    // Declare the function to generate a custom token
    const generateCustomToken = function (length: number) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
      let token = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters[randomIndex];
      }
      return token;
    }

    // Check if deviceToken already exists in localStorage
    let deviceToken = localStorage.getItem('deviceToken');
    // console.log(deviceToken, 'token');
    if (!deviceToken || deviceToken.length < 180) {
      // If no deviceToken exists or it is invalid, generate a new one
      deviceToken = generateCustomToken(180);
      localStorage.setItem('deviceToken', deviceToken.toString());
      // console.log(deviceToken, 'ttt');
    } else {
      // console.log('Using existing device token:', deviceToken);
    }
  }
  getProducts //       this.loader.stop();
    () {
    throw new Error("Method not implemented.");
  }
  editData(data: any, it: any) {
    throw new Error('Method not implemented.');
  }
  // private apiUrl = 'hhttps://dummyjson.com/products'; // Example API endpoint
  constructor(private http: HttpClient, private toastr: ToastrService, private loader: NgxUiLoaderService, private storageService: LocalStorageProvider, public dialogRef: MatDialog,) { }
  Login() {
    this.dialogRef.open(LoginComponent, {
      maxHeight: '100vh',
      width: '800px',
    })

  }
  post(payload: any, url: string, parameter: any): Observable<any> {
    //    this.loader.start();
    let params = new HttpParams()
    params = params.appendAll(parameter)
    return this.http.post<any>(url, payload, { params: params }).pipe(
      catchError(err => {
        this.loader.stop();
        return throwError(err.error);
      }),
      map(resp => {
        this.loader.stop();
        return resp
      }),
    )
  }

  tt: any;

  async postWithFetch(formData: FormData, url: string, parameters: any = {}) {
    // Create a query string from parameters (if any)
    const queryString = new URLSearchParams(parameters).toString();
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    this.tt = localStorage.getItem('stoken');
    try {
      // Perform the POST request using fetch API
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'stoken': this.tt,
        }
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`File upload failed: ${response.statusText}`);
      }

      // Parse the response (assuming it's JSON)
      const data = await response.json();
      // console.log('Response from server:', data.result);
      if (data.success) {
        if (data.result) {
          // Return the response data or whatever structure is needed
          return data.result.urls[0];
        }
      } else {
        console.log("No file uploaded.")
      }

    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;  // Rethrow the error for further handling if needed
    }
  }

  put(payload: any, queryParams: any, url: string): Observable<any> {
    let params = new HttpParams()
    params = params.appendAll(queryParams)
    return this.http.put<any>(url, payload, { params: params }).pipe(
      catchError(err => {

        this.loader.stop();
        return throwError(err.error);
      }),
      map(resp => {
        this.loader.stop();
        return resp
      }),
    )
  }

  get(payload: any, url: any, stoken?: string): Observable<any> {
    // this.loader.start();

    let params = new HttpParams();
    params = params.appendAll(payload);

    // Set headers
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${stoken}` // Assuming the token is a Bearer token
    });

    return this.http.get(url, { params: params, headers: headers }).pipe(
      catchError(err => {
        // this.loader.stop();
        return throwError(err.error);
      }),
      map((response: any) => {
        // this.loader.stop();
        return response;
      })
    );
  }


  // get(payload: any, url: any): Observable<any> {
  //   this.loader.start();
  //   let params = new HttpParams()
  //   params = params.appendAll(payload)
  //   return this.http.get(url, { params: params }).pipe(
  //     catchError(err => {

  //       this.loader.stop();
  //       return throwError(err.error);
  //     }),
  //     map((response: any) => {
  //       this.loader.stop();
  //       return response;
  //     }),

  //   )
  // }

  delete(payload: any, url: any): Observable<any> {
    // this.loader.start();

    // Create a new HttpParams instance to append the parameters
    let params = new HttpParams();

    // Ensure that we are not appending undefined values
    // if (payload.productId) {
    //   params = params.append('product_id', payload.productId);
    // } else {
    //   console.error("productId is undefined in payload.");
    //   this.toastr.error("Product ID is missing in request.");
    //   this.loader.stop();
    //   return throwError("Product ID is missing.");
    // }
    params = params.appendAll(payload)
    return this.http.delete(url, { params: params }).pipe(
      catchError(err => {
        // this.loader.stop();
        return throwError(err.error);  // Returning the error as a stream
      }),
      map((response: any) => {
        // this.loader.stop();
        return response;  // Return the response as a stream
      }),
    );
  }


  //  refreshToken(){
  //   const url = `${environment.baseURl}/v1/company/refresh_token`;
  // }

  refreshToken() {
    const token = localStorage.getItem('stoken')
    const url = `${environment.baseURl}/v1/company/refresh-token`;
    const payload = {
      stoken: token
    };
    return this.put(payload, {}, url);
  }
  scrollToToast() {
    // Find the toast container or the last toast message
    const toastElement = document.querySelector('.toast-bottom-right');

    // Ensure the toast is visible in the viewport without scrolling up
    if (toastElement) {
      toastElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest', // Scroll to the nearest viewport edge, based on where the user is
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  getValueOrDefault(value: any, defaultValue: any = null): any {
    // Handle 'null' values to show as empty in input
    if (value === 'null' || value === null) {
      return ''; // Display nothing in input field
    }

    // For numeric fields like AMC and SLA, return empty string if value is 0 for display
    if ((value === 0) && (typeof value === 'number')) {
      return ''; // Display empty input for 0
    }

    // Return actual values for other types
    return value || ''; // For other fields, return the value as is
  }
  onKeyPressNumericOnly(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9) and control keys like backspace
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  FB: any;
  initFacebookSdk(): void {
    (window as any).fbAsyncInit = () => {
      this.FB.init({
        appId: '1393146251160836', // Replace with your App ID
        cookie: true, // Enable cookies to allow server access
        xfbml: true, // Parse social plugins on this page
        version: 'v16.0', // Use the Graph API version
      });
      console.log('Facebook SDK initialized');
    };
  }

  // Login with Facebook
  login(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.FB.login(
        (response: any) => {
          if (response.authResponse) {
            resolve(response.authResponse);
          } else {
            reject('User cancelled login or did not fully authorize.');
          }
        },
        { scope: 'email,public_profile' }
      );
    });
  }
  getUserData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.FB.api('/me', { fields: 'id,name,email,picture' }, (response: any) => {
        if (response && !response.error) {
          resolve(response);
        } else {
          reject(response.error);
        }
      });
    });
  }

  
  
}
