import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConstant } from 'src/app/core/_constants/app.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';
import { RejectPurchaseOrderComponent } from '../reject-purchase-order/reject-purchase-order.component';

@Component({
  selector: 'app-delay',
  templateUrl: './delay.component.html',
  styleUrls: ['./delay.component.scss']
})
export class DelayComponent implements OnInit {
  open: any;
  orderId: any
  submitted = false;
  myForm: any;
  milestoneListData: any;
  constructor(private fb:FormBuilder, private dialog: MatDialog, public dialogRef: MatDialogRef<DelayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: ApiServicesService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.createForm();
    this.milestoneList();
  }
  delay() {
    const dialogRef = this.dialog.open(DelayComponent, {
      maxHeight: '100vh',
      width: '421px',
      data: {
        heading: 'Ready to dispatch?',
        report: 'Delay',
        cancel: 'Yes'
      }
    })
  }

  createForm() {
    this.submitted = false;
    this.myForm = this.fb.group({
      id: ['',Validators.required],
      is_delay: ['', [Validators.required]],

    })
  
  }

  milestoneList(){
    let params={
      parent_id:this.data.id
    }
    this.service.get(params, `${API_ROUTES.PurchaseOrder.Milestonelist}`).pipe().subscribe((res) => {
      
     this.milestoneListData = res.result;
    }
    )
  }
  get f() { return this.myForm.controls; }

  onSubmit() {
    this.submitted = true;
    if(this.myForm.valid){
      let findObj = this.milestoneListData.find((el:any)=> el.id == this.myForm.value['id']);
      let dataToPost = {
        id: this.myForm.value['id'],
        is_delay: this.myForm.value['is_delay'],
        mile_delay : findObj.milestone_name
      }
      this.service.put(dataToPost, {}, API_ROUTES.PurchaseOrder.dispatch).pipe().subscribe((res => {
          this.dialogRef.close(true);
      })
      )
    }
  }

}
