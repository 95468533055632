import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
} from "@angular/common/http";
import {
  catchError,
  switchMap,
  filter,
  take
} from "rxjs/operators";
import { BehaviorSubject, Observable, from, of, throwError } from "rxjs";
import { LocalStorageProvider } from "src/app/services/storage/storage.service";
import { AuthService } from "src/app/services/authguard/auth.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiServicesService } from "src/app/services/apiServices/api-services.service";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private storageService: LocalStorageProvider,
    private loader: NgxUiLoaderService,
    private common_service: CommonService,
    private httpClient: HttpClient,
    private router: Router,
  ) { }
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private isRefreshing = false;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      return throwError(() => {
        return new HttpErrorResponse({ error: "Internet is required." });
      });
    } else {
      /*Fetching the token from the localStorage and adding in Authorisation Headers */
      let apiToken = this.storageService.getItem("stoken");
      //modified
      let refreshToken = this.storageService.getItem("refresh_token");

      if (!request.headers.has("Content-Type")) {
        request = request.clone({
          headers: request.headers.set("Content-Type", "application/json"),
        });
      }
      if (!request.headers.has("Accept")) {
        request = request.clone({
          headers: request.headers.set("Accept", "*/*"),
        });
      }
      if (!request.headers.has("Access-Control-Allow-Origin")) {
        request = request.clone({
          headers: request.headers.set("Access-Control-Allow-Origin", "*"),
        });
      }
      if (apiToken) {
        request = request.clone({
          headers: request.headers.set("stoken", `${apiToken}`),
        });
      }
      if (refreshToken) {
        request = request.clone({
          headers: request.headers.set("refresh_token", `${refreshToken}`),
        });
      }
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if ((error.error?.error_code == 403 &&
            error.error?.result?.error.toLowerCase() == "jwt expired")) {
            if (!this.isRefreshing) {
              this.isRefreshing = true;
              this.refreshTokenSubject.next(null);
              return this.httpClient
                .put(
                  // "https://dev.raftaarr.com:5040/api/v1/company/refresh-token",
                  `${environment.baseURl}/v1/company/refresh-token`,
                  {}
                )
                .pipe(
                  take(1),
                  switchMap((res: any) => {
                    this.isRefreshing = false;
                    const { new_token, new_refresh_token } = res.result;
                    this.refreshTokenSubject.next({ new_token, new_refresh_token });
                    this.storageService.setItem("stoken", new_token);
                    this.storageService.setItem(
                      "refresh_token",
                      new_refresh_token
                    );
                    this.storageService.setItem(
                      "refresh_token2",
                      new_refresh_token
                    );
                    return next.handle(
                      request.clone({
                        setHeaders: {
                          stoken: new_token,
                          refresh_token: new_refresh_token
                        },
                      })
                    );
                  })
                );
            } else {
              return this.refreshTokenSubject.pipe(
                filter((token) => {
                  return token != null
                }),
                switchMap((tokenObj) => {
                  return next.handle(
                    request.clone({
                      setHeaders: {
                        stoken: tokenObj.new_token,
                        refresh_token: tokenObj.new_refresh_token
                      },
                    })
                  );
                })
              );
            }
          } else if (error.error?.message == "Session expired , please login again" && error.error?.error_code == 401) {
            localStorage.removeItem('stoken');
            this.storageService.clearAll();
            this.router.navigate(['']);
          }
          else if ((error.error.error_code == 403 && (error.error.message.toLowerCase() == "token provided is not valid" || error.error.message.toLowerCase() == "no token provided."))) {
            this.loader.stop();
            this.common_service.logout();
          }
          return throwError(error);
        })
      );
    }
  }
}
