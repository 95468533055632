import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { ApiServicesService } from 'src/app/services/apiServices/api-services.service';

@Component({
  selector: 'app-likes',
  templateUrl: './likes.component.html',
  styleUrls: ['./likes.component.scss']
})
export class LikesComponent implements OnInit {
  likesList: any;

  constructor(private service:ApiServicesService,public dialogRef: MatDialogRef<LikesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getLikes();
  }

  getLikes(){
    // let params={
    //   post_id:this.data.id
    // }
    // this.service.get(params,`${API_ROUTES.Home.likeList}`).pipe().subscribe((res)=>{

    //   this.likesList = res.result;


    //   // if(res.result.Post!=null){
    //   //   this.data = res.result.Post;
    //   //
    //   // }else if(res.result.Requirement!=null)
    //   // {
    //   //   this.data = res.result.Requirement;
    //   // }


    // },(error)=>{

    //   // this.toastr.error(error.message)

    // }
    // )
  }

}
