<!-- <section style="background-image: url('assets/images/new_images/enquiry.jpg');" class="py-5">
  <div class="container-fluid py-5">
<div class="row">
<div class="col-md-12">
  <div class="ctabox">
<h3> Tell us Your Enquiries</h3>
<a href="" class="btn"> Post Your Enquiries</a>
</div>
</div>
</div>
</div>
</section> -->
<div class="row">

  <div class="col-md-12 modal-header pb-0 text-end">
    <button class="button-close" mat-button [mat-dialog-close]="false">
      <img src="assets/images/arrow_close.svg">
    </button>
  </div>
</div>
<div class="p-0 mainnn">
  <div class="row m-0 d-flex  justify-content-center bg-color">
    <div class="col-lg-12 reset ">
      <h3 class="mb-0  loginaccount">Post Your Enquiry</h3>
      <form [formGroup]="loginForm" (submit)="onSubmit(loginForm.value)">
        <div class="phonedetail d-grid ">
          <div class="customwidth position-relative mt-2">
            <input type="text" class="form-control emaildata ps-3" maxlength="50" placeholder="Full Name"
              formControlName="full_name">
            <div
              *ngIf="loginForm.get('full_name')?.invalid && (loginForm.get('full_name')?.touched || loginForm.get('full_name')?.dirty)"
              class="error-message">
              Full Name is required.
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <input type="email" class="form-control emaildata ps-3" maxlength="50"
              (keydown)="validators.avoidSpace($event)" placeholder="Email" formControlName="email">
            <div
              *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)"
              class="error-message">
              <div *ngIf="loginForm.get('email')?.errors?.required">Email is required.</div>
              <div *ngIf="loginForm.get('email')?.errors?.pattern">Invalid email format.</div>
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <mat-form-field appearance="fill" class="mat-select-display">
              <mat-select placeholder="Select Industry" #select1 (openedChange)="onSelectOpenClose($event, 'industry')"
                formControlName="industry_id">
                <mat-option *ngFor="let category1 of industry" [value]="category1.id">
                  {{category1.industry_name}}
                </mat-option>
                <mat-option *ngIf="industry?.length == 0" disabled>No items available</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="loginForm.get('industry_id')?.invalid && (loginForm.get('industry_id')?.touched || loginForm.get('industry_id')?.dirty)"
              class="error-message">
              Industry is required.
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <input type="text" class="form-control emaildata ps-3" maxlength="50" placeholder="Product Name"
              formControlName="product_name">
            <div
              *ngIf="loginForm.get('product_name')?.invalid && (loginForm.get('product_name')?.touched || loginForm.get('product_name')?.dirty)"
              class="error-message">
              Product Name is required.
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <input type="text" class="form-control emaildata ps-3" maxlength="50" placeholder="Company Name"
              formControlName="company_name">
            <div
              *ngIf="loginForm.get('company_name')?.invalid && (loginForm.get('company_name')?.touched || loginForm.get('company_name')?.dirty)"
              class="error-message">
              Company Name is required.
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <input type="text" class="form-control emaildata ps-3" maxlength="50" placeholder="Location"
              formControlName="location">
            <div
              *ngIf="loginForm.get('location')?.invalid && (loginForm.get('location')?.touched || loginForm.get('location')?.dirty)"
              class="error-message">
              Location is required.
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <mat-form-field appearance="fill" class="mat-select-display">
              <mat-select placeholder="Brands" #select2 panelClass="s1" formControlName="brand_id">
                <mat-option *ngFor="let category1 of brands" [value]="category1.id">
                  {{category1.brand_name}}
                </mat-option>
                <mat-option *ngIf="brands?.length == 0" disabled>No items available</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="loginForm.get('brand_id')?.invalid && (loginForm.get('brand_id')?.touched || loginForm.get('brand_id')?.dirty)"
              class="error-message">
              Brand is required.
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <mat-form-field appearance="fill" class="mat-select-display">
              <mat-select placeholder="Required Loan" #select3 panelClass="s1" formControlName="loan_required">
                <mat-option *ngFor="let category1 of loan" [value]="category1.id">
                  {{category1.title}}
                </mat-option>
                <mat-option *ngIf="filteredCat.length === 0" disabled>No items available</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="loginForm.get('loan_required')?.invalid && (loginForm.get('loan_required')?.touched || loginForm.get('loan_required')?.dirty)"
              class="error-message">
              Loan requirement is required.
            </div>
          </div>

          <div class="customwidth position-relative mt-2">
            <textarea type="text" class="form-control emaildata ps-3" maxlength="1500"
              placeholder="Brief Description (max 1500 words)" formControlName="description"></textarea>
            <div
              *ngIf="loginForm.get('description')?.invalid && (loginForm.get('description')?.touched || loginForm.get('description')?.dirty)"
              class="error-message">
              Description is required.
            </div>
          </div>
        </div>
        <div class="customwidth position-relative mt-3">
          <button type="submit" class="loginbtn" [disabled]="loginForm.invalid">Post Enquiry</button>
        </div>
      </form>

    </div>
  </div>
</div>