import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// import { SubjectserviceService } from 'src/app/services/subjectService/subjectservice.service';

@Component({
  selector: "app-share-testimonial",
  templateUrl: "./share-testimonial.component.html",
  styleUrls: ["./share-testimonial.component.scss"],
})
export class ShareTestimonialComponent implements OnInit {
  open: any;
  comment: any;
  public form: FormGroup;
  rating3: number;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ShareTestimonialComponent>
  ) {
    this.rating3 = 0;
    this.form = this.fb.group({
      rating1: ["", Validators.required],
      rating2: [4],
    });
  }

  ngOnInit(): void {
  }

  // commentCode(e: any) {
  //   const comment = e.target.value; // Get the value of the comment input
  //   // if (comment.trim() === "" || !comment) {
  //   //   this.comment = true;
  //   // } else {
  //   //   this.comment = false;
  //   // }
  //   if(comment && comment.trim()!=''){
  //     this.error=false;
  //   }
  //   else{
  //     this.error=true;
  //   }
  // }
  clearCommentError() {
    this.error = false; // Clear the "Comment is required" error as the user types
  }
  error: any;
  ratingError: any;
  submitBtn() {
    let rating = this.form.controls["rating1"].value;
    if (!this.comment) {
      this.error = true;
    } else if (!rating) {
      this.ratingError = true;
    } else {
      this.ratingError = false;
      this.error = false;
      this.dialogRef.close({
        result: true,
        commentData: this.comment,
        rating: this.form.value["rating1"],
      });
    }
  }
}
