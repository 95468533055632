import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private dataSource = new BehaviorSubject('123');
  data: any = this.dataSource.asObservable();

  constructor() { }

  setData(key: any, value: any) {
    this.data[key] = value
  }
  getDataByKey(key: any) {
    return this.data[key];
  }
  getData() {
    return this.data;
  }

  private sendProductData = new BehaviorSubject<any>({});
  product$ = this.sendProductData.asObservable();

  sendProduct(data: any) {
    this.sendProductData.next(data);
  }

  // getUrl(){
  //   return this.url;
  //}

  private redirectToMarketpalce = new BehaviorSubject<string>('');
  redirect$ = this.redirectToMarketpalce.asObservable();

  setRedirects(data:any){
    this.redirectToMarketpalce.next(data);
  }

  private saveSearchValue = new BehaviorSubject<string>('');
  searchValue$ = this.saveSearchValue.asObservable();

  setSearchValue(data:any){
    this.saveSearchValue.next(data);
  }

  private companyInfo = new BehaviorSubject<any>({});
  companyInfo$ = this.companyInfo.asObservable();

  setCompanyInfo(data:any){
    this.companyInfo.next(data);
  }
}
