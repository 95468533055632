<div class="modal-body pt-0 px-md-3 px-0">
    <form [formGroup]="createPassword" (submit)="onSubmit()">
        <div class="d-flex justify-content-between align-items-center mt-3">
            <label class="mb-0">Password</label>
            <!-- <img src="assets/images/close.png" alt="" mat-button (click)="close()"> -->
        </div>
        <div class="input-container position-relative">
            <!-- <p class="position-absolute c_input">+91</p> -->
            <!-- <p class="counternumber px-2 text-center"><span
                    *ngIf="countnumber.length<=9 && countnumber.length>0">0</span>{{countnumber.length}}/10
            </p> -->
            <input [type]="password" formControlName="password" [ngClass]="{'is-invalid': submitted && f.password.errors}"  placeholder="Enter password" maxlength="16"/>
            <div  class="cursor-pointer" (click)="onClick()">
                <img class="smsdata" *ngIf="!show" src="assets/images/lock@1x.png" alt=""  width="20px" height="20px">
                <img class="smsdata smsdata1" *ngIf="show" src="assets/images/unlock.png" alt="" width="20px" height="20px">
            </div>
            <small [class]="f.password.errors?.pattern?'mt-1':'password-message mt-1'">
                * Password can contain alphabets,numbers,special characters and must be of 8 characters
            </small>
            <div *ngIf="f.password.invalid" class="invalid-feedback">
                <div *ngIf="f.password?.errors?.required">password is required</div>
                <!-- <div *ngIf="f.phone?.errors?.pattern">Phone must have 10 digits.</div> -->
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-5">
            <label class="mb-0">Confirm Password</label>
            <!-- <img src="assets/images/close.png" alt="" mat-button (click)="close()"> -->
        </div>
        <div class="input-container position-relative">
            <!-- <p class="position-absolute c_input">+91</p> -->
            <!-- <p class="counternumber px-2 text-center"><span
                    *ngIf="countnumber.length<=9 && countnumber.length>0">0</span>{{countnumber.length}}/10
            </p> -->
            <input [type]="confirmpassword" formControlName="confirmPassword" [ngClass]="{'is-invalid': submitted && f.confirmPassword.errors}"  placeholder="Enter confirm password"/>
            <div  class="cursor-pointer" (click)="confirm()">
                <img class="smsdata" *ngIf="!confirmshow" src="assets/images/lock@1x.png" alt=""  width="20px" height="20px">
                <img class="smsdata smsdata1" *ngIf="confirmshow" src="assets/images/unlock.png" alt="" width="20px" height="20px">
            </div>
            <div *ngIf="f.confirmPassword.invalid" class="invalid-feedback">
                <div *ngIf="f.confirmPassword?.errors?.required">confirm password is required</div>
                <div *ngIf="f.confirmPassword?.errors?.confirmPasswordValidator">Passsword
                    and
                    Confirm Password didn't match. </div>
                <!-- <div *ngIf="f.phone?.errors?.pattern">Phone must have 10 digits.</div> -->
            </div>
        </div>
        <div class="text-center btn-container mt-3">
            <button type="submit" class="btn report">Create Password</button>
        </div>
    </form>
</div>
